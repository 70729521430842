import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { Model, VueForm } from '@aon/cfs-forms';

const coiRejectModel = new Model({
  reasonText: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(500),
    },
  },
});

export default function CoiRejectForm() {
  return VueForm({
    fields: coiRejectModel.getFields(),
    validations: coiRejectModel.getValidations(),
  });
}
