<template>
  <v-dialog v-model="value" max-width="30rem" persistent>
    <v-card>
      <v-row dense no-gutters class="justify-space-around pt-5">
        <p class="font-weight-bold aon-peacock--text">
          {{ $t('coiReview.reviewPanel.rejectionReason') }}
        </p>
      </v-row>
      <v-row dense no-gutters>
        <v-textarea
          v-model="$v.fields.reasonText.$model"
          :error-messages="form.errors($v.fields.reasonText)"
          outlined
          no-resize
          class="px-5"
        />
      </v-row>
      <v-row dense no-gutters class="justify-space-around pb-5">
        <AppButton style="min-width: 10rem" variation="ghost" @click="close()">
          {{ $t('coiReview.reviewPanel.btnCancel') }}
        </AppButton>
        <AppButton style="min-width: 10rem" @click="submit()">
          {{ $t('coiReview.reviewPanel.btnRequestChanges') }}
        </AppButton>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import CoiRejectForm from '@/lib/forms/coiReject';

export default {
  name: 'CoiRejectDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: new CoiRejectForm(),
    };
  },
  computed: {
    $v() {
      return this.form.$v;
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit('submit', {
        status: 'rejected',
        rejectionReason: this.$v.fields.reasonText.$model,
      });
      this.form = new CoiRejectForm();
    },
    close() {
      this.form = new CoiRejectForm();
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
