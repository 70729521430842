<template>
  <div class="insurance">
    <h2 class="insurance-name">
      {{ $t('coiReview.validationPanel.generalLiability.title') }}
    </h2>

    <LabeledInput
      :label="$t('coiReview.validationPanel.policyNumber')"
      :required="true"
    >
      <v-text-field
        v-model="$v.fields.generalLiability.policyNumber.$model"
        :error-messages="
          insurance.errors($v.fields.generalLiability.policyNumber)
        "
        validate-on-blur
        outlined
        dense
        type="text"
      />
    </LabeledInput>

    <AutocompleteCarriers
      :insurance="insurance"
      :insurancelob="$v.fields.generalLiability"
    />

    <LabeledInput
      :label="$t('coiReview.validationPanel.effectiveDate')"
      :required="true"
    >
      <DatePicker
        :date.sync="effectiveDate"
        :error-messages="
          insurance.errors($v.fields.generalLiability.effectiveDate)
        "
        label=""
        dense
      />
    </LabeledInput>

    <LabeledInput
      :label="$t('coiReview.validationPanel.expirationDate')"
      :required="true"
    >
      <DatePicker
        :date.sync="expirationDate"
        :error-messages="
          insurance.errors($v.fields.generalLiability.expirationDate)
        "
        label=""
        dense
      />
    </LabeledInput>

    <div v-if="!isMigrated">
      <LabeledInput
        :label="$t('coiReview.validationPanel.generalLiability.eachOccurrence')"
        :required="true"
      >
        <v-text-field
          v-model.number="$v.fields.generalLiability.eachOccurrence.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.eachOccurrence)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="$t('coiReview.validationPanel.generalLiability.aggregate')"
        :required="true"
      >
        <v-text-field
          v-model.number="$v.fields.generalLiability.aggregate.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.aggregate)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="$t('coiReview.validationPanel.generalLiability.deductible')"
        :required="true"
      >
        <v-text-field
          v-model.number="$v.fields.generalLiability.deductible.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.deductible)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>
    </div>

    <div v-if="this.$v.fields.isMigrated.$model">
      <Endorsement
        :options="clientList"
        :label="$t('endorsements.additionalInsured')"
        :display-options="this.$v.fields.generalLiability.additionalInsured"
        @updateList="updateAdditionalInsured"
      />

      <Endorsement
        :options="clientList"
        :label="$t('endorsements.waiverOfSubrogation')"
        :display-options="this.$v.fields.generalLiability.waiverOfSubrogation"
        @updateList="updateWaiverOfSubrogation"
      />

      <Endorsement
        :options="clientList"
        :label="$t('endorsements.primaryNonContributory')"
        :display-options="
          this.$v.fields.generalLiability.primaryNonContributory
        "
        @updateList="updatePrimaryNonContributory"
      />
    </div>
    <div v-else>
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.generalLiability.additionalInsured.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.additionalInsured)
          "
          color="aon-peacock"
          :label="
            $t('coiReview.validationPanel.generalLiability.additionalInsured')
          "
        />
      </div>

      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.generalLiability.waiverOfSubrogation.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.waiverOfSubrogation)
          "
          color="aon-peacock"
          :label="
            $t('coiReview.validationPanel.generalLiability.waiverOfSubrogation')
          "
        />
      </div>

      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.generalLiability.primaryNonContributory.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.primaryNonContributory)
          "
          color="aon-peacock"
          :label="
            $t(
              'coiReview.validationPanel.generalLiability.primaryNonContributory'
            )
          "
        />
      </div>
    </div>

    <!-- Hide this fields until product team announce these changes -->
    <div v-if="false">
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.generalLiability.perPolicy.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.perPolicy)
          "
          color="aon-peacock"
          :label="$t('coiReview.validationPanel.generalLiability.perPolicy')"
        />
      </div>
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.generalLiability.perProject.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.perProject)
          "
          color="aon-peacock"
          :label="$t('coiReview.validationPanel.generalLiability.perProject')"
        />
      </div>
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.generalLiability.perLocation.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.perLocation)
          "
          color="aon-peacock"
          :label="$t('coiReview.validationPanel.generalLiability.perLocation')"
        />
      </div>
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.generalLiability.coverOccurrence.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.coverOccurrence)
          "
          color="aon-peacock"
          :label="
            $t('coiReview.validationPanel.generalLiability.coverOccurrence')
          "
        />
      </div>
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.generalLiability.coverClaimsMade.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.coverClaimsMade)
          "
          color="aon-peacock"
          :label="
            $t('coiReview.validationPanel.generalLiability.coverClaimsMade')
          "
        />
      </div>
    </div>

    <div v-if="!isMigrated">
      <LabeledInput
        :label="$t('coiReview.validationPanel.generalLiability.damageToRented')"
      >
        <v-text-field
          v-model.number="
            $v.fields.generalLiability.damageToRentedPremises.$model
          "
          :error-messages="
            insurance.errors($v.fields.generalLiability.damageToRentedPremises)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="$t('coiReview.validationPanel.generalLiability.productComp')"
      >
        <v-text-field
          v-model.number="$v.fields.generalLiability.productsComp.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.productsComp)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="$t('coiReview.validationPanel.generalLiability.personalAdv')"
        class="text-no-wrap"
      >
        <v-text-field
          v-model.number="$v.fields.generalLiability.personalAdvInjury.$model"
          :error-messages="
            insurance.errors($v.fields.generalLiability.personalAdvInjury)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>
    </div>
  </div>
</template>

<script>
import AutocompleteCarriers from '@/components/CoiReview/AutocompleteCarriers';
import Endorsement from '@/components/Endorsement';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';

export default {
  name: 'GeneralLiability',
  components: {
    AutocompleteCarriers,
    Endorsement,
    DatePicker,
    LabeledInput,
  },
  props: {
    insurance: {
      type: Object,
      default: () => {},
    },
    clientList: {
      type: Array,
      default: () => [],
    },
    isMigrated: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    $v() {
      return this.insurance.$v;
    },
    effectiveDate: {
      get() {
        return this.$v.fields.generalLiability.effectiveDate.$model
          ? this.$moment
              .utc(this.$v.fields.generalLiability.effectiveDate.$model)
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$v.fields.generalLiability.effectiveDate.$model = value
          ? this.$moment.utc(value).format()
          : null;
      },
    },
    expirationDate: {
      get() {
        return this.$v.fields.generalLiability.expirationDate.$model
          ? this.$moment
              .utc(this.$v.fields.generalLiability.expirationDate.$model)
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$v.fields.generalLiability.expirationDate.$model = value
          ? this.$moment.utc(value).format()
          : null;
      },
    },
  },
  watch: {
    isMigrated(newValue) {
      if (newValue) {
        this.$v.fields.generalLiability.eachOccurrence.$model = 0;
        this.$v.fields.generalLiability.aggregate.$model = 0;
        this.$v.fields.generalLiability.deductible.$model = 0;
      }
    },
  },
  methods: {
    updateAdditionalInsured({ selectedValues }) {
      this.$v.fields.generalLiability.endorsements.additionalInsured.$model = selectedValues;
    },
    updateWaiverOfSubrogation({ selectedValues }) {
      this.$v.fields.generalLiability.endorsements.waiverOfSubrogation.$model = selectedValues;
    },
    updatePrimaryNonContributory({ selectedValues }) {
      this.$v.fields.generalLiability.endorsements.primaryNonContributory.$model = selectedValues;
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance {
  border-top: $border-gray-06;
  border-bottom: $border-gray-06;
  padding: 20px;

  &-name {
    color: #46535e;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 18px;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;

  & .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
