<template>
  <LabeledInput
    :label="$t('coiReview.validationPanel.insuranceCarrier')"
    :required="true"
  >
    <v-autocomplete
      v-model="insurancelob.carrier.$model"
      :error-messages="insurance.errors(insurancelob.carrier)"
      :items="carriers"
      :loading="searching"
      :menu-props="autocompleteMenuProps"
      :placeholder="$t('coiReview.validationPanel.insuranceCarrier')"
      :search-input.sync="search"
      item-value="name"
      item-text="name"
      clearable
      outlined
      @change="updateCarrierData"
      @click:clear="onClear"
    >
      <template v-slot:no-data class="scroll-to-top">
        <v-list-item>
          <v-list-item-title>
            {{
              $t(
                `coiReview.validationPanel.${
                  hasSearched ? 'noCarriers' : 'searchCarriers'
                }`
              )
            }}
          </v-list-item-title>
        </v-list-item>
        <v-divider class="py-2" />
        <div class=" d-flex flex-column items-center px-3">
          <div class=" d-flex flex-row justify-between text-no-wrap">
            <span class="px-3">
              {{ $t('coiReview.validationPanel.insuranceCarrierNaic') }}
            </span>
            <v-text-field
              v-model="insurancelob.carrierNaic.$model"
              class="hide-number-spinner"
              dense
              outlined
              type="number"
              validate-on-blur
            />
          </div>
          <AppButton
            class="mx-5 report-issue"
            full-width
            @click="addItemToCarriers"
          >
            {{ 'Add as new Insurance Carrier' }}
          </AppButton>
        </div>
      </template>
    </v-autocomplete>
  </LabeledInput>
</template>

<script>
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import { mapState } from 'vuex';
import { debounce } from 'lodash-es';

export default {
  name: 'AutocompleteCarriers',
  components: {
    LabeledInput,
  },
  props: {
    insurance: {
      type: Object,
      default: () => {},
    },
    insurancelob: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      autocompleteMenuProps: {
        closeOnContentClick: false,
      },
      carriers: [],
      hasSearched: false,
      search: null,
      searching: false,
      selected: [],
    };
  },
  computed: {
    ...mapState('carriers', {
      computedCarriers: state => state.carriers,
    }),
  },
  watch: {
    search: debounce(function(query) {
      this.hasSearched = true;

      if (this.searching || query === null) {
        return;
      }
      this.searching = true;

      this.carriers = this.searchCarrier(query);
      this.searchError = true;
      this.searching = false;
    }, 500),
  },
  async mounted() {
    //beforeMount
    if (!this.computedCarriers || this.computedCarriers.length === 0) {
      await this.$store.dispatch('carriers/getAllCarriers');
    }
    this.carriers = [...this.computedCarriers];
    if (
      this.insurancelob.carrier.$model &&
      !this.carriers.find(
        el =>
          el.name.toLowerCase() ===
          this.insurancelob.carrier.$model.toString().toLowerCase()
      )
    ) {
      this.carriers = [
        ...this.carriers,
        { name: this.insurancelob.carrier.$model },
      ];
      this.$store.commit('carriers/setCarriers', this.carriers);
    } else {
      this.updateCarrierData(this.insurancelob.carrier.$model);
    }
  },
  methods: {
    onClear() {
      this.insurancelob.carrierNaic.$model = null;
      this.carriers = this.computedCarriers;
    },
    searchCarrier(query) {
      if (!query || query.length === 0) {
        return this.computedCarriers;
      }
      return this.carriers.filter(
        car =>
          car.id === query ||
          car.aonid === query ||
          car.naic === query ||
          car.name?.toLowerCase().indexOf(query.toString().toLowerCase()) >
            -1 ||
          query === ''
      );
    },
    updateCarrierData(event) {
      const currentCarrier = this.searchCarrier(event);
      this.carriers = currentCarrier;
      if (currentCarrier && currentCarrier.length === 1) {
        this.insurancelob.carrierAonId.$model = currentCarrier[0]?.aonid;
        this.insurancelob.carrierNaic.$model = currentCarrier[0]?.naic;
      }
    },
    addItemToCarriers() {
      if (this.search?.length > 0) {
        this.carriers = [
          ...this.computedCarriers,
          {
            aonid: null,
            naic: Math.round(this.insurancelob.carrierNaic.$model),
            name: this.search,
          },
        ];
        this.$store.commit('carriers/setCarriers', this.carriers);
      }
    },
  },
};
</script>
