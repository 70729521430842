<template>
  <v-autocomplete
    v-model="selectedOption"
    dense
    outlined
    clearable
    :items="options"
    item-text="companyName"
    item-value="companyName"
    return-object
    prepend-inner-icon="mdi-magnify"
    :placeholder="$t('endorsements.inputPlaceholder')"
  >
  </v-autocomplete>
</template>

<script>
export default {
  name: 'EndorsementOptions',
  props: {
    id: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOption: '',
    };
  },
  watch: {
    selectedOption(currentOption) {
      this.$emit('onChange', {
        id: this.id,
        companyName: currentOption?.companyName || '',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input__control {
  border: 1px solid blue !important;
}
</style>
