import axios from 'axios';
import { uploadDocument } from '@/api/insurancePolicies';
import { downloadDocument } from '@aon/cfs-insurance/src/api/document';

const baseUrl = 'api/insurance-policies';
const vendorUrl = vendorId => `api/vendors/${vendorId}`;

/**
 * @param {string} vendorId
 * @param {object} params
 * @param {string} params.type Insurance Type
 */
export function getInsurancePolicies(vendorId, params) {
  return axios.get(`${vendorUrl(vendorId)}/insurance-policies`, {
    params,
  });
}

export function getInsurancePolicy(policyId) {
  return axios.get(`${baseUrl}/${policyId}`);
}

export function getExtractedPolicy(extractionId) {
  return axios.get(
    `api/coi-data-extraction/get-coverage?policyId=${extractionId}`
  );
}

export function createInsurancePolicy(policy, tenantId = null) {
  let config = { headers: {} };
  if (tenantId) config.headers['X-TENANT-ID'] = tenantId;
  return axios.post(baseUrl, policy, config);
}

export function createApprovedInsurancePolicy(policy, tenantId = null) {
  let config = { headers: {} };
  if (tenantId) config.headers['X-TENANT-ID'] = tenantId;
  return axios.post(
    `${baseUrl}/create-approved-insurance-policy`,
    policy,
    config
  );
}

export function createApprovedMultiplePolicies(policies, tenantId = null) {
  let config = { headers: {} };
  if (tenantId) config.headers['X-TENANT-ID'] = tenantId;
  return axios.post(
    `${baseUrl}/create-approved-insurance-policies`,
    policies,
    config
  );
}

export function rejectPolicy(policyId, fileId, rejectionReason) {
  let config = { headers: {} };
  if (fileId) config.headers['X-CORRELATION-ID'] = fileId;
  return axios.put(
    `api/coi-data-extraction/update-coi-rejection/${policyId}`,
    {
      coiStatus: 'REJECTED',
      rejectionReason,
    },
    config
  );
}

export function deleteInsurancePolicy(policyId) {
  return axios.delete(`${baseUrl}/${policyId}`);
}

export function updateInsurancePolicy(policyId, policy, tenantId = null) {
  let config = { headers: {} };
  if (tenantId) config.headers['X-TENANT-ID'] = tenantId;
  return axios.put(`${baseUrl}/${policyId}`, policy, config);
}

export function uploadInsuranceCertificate({ file, policyId, tenantId }) {
  return uploadDocument({
    correlationId: policyId,
    type: 'COI',
    file,
    tenantId,
  });
}

export function downloadInsuranceCertificate(documentId) {
  return downloadDocument(documentId);
}
