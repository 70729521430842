import { abbreviateNumber } from '@aon/cfs-utils';
import { translator } from '@aon/cfs-utils/lib/translation';

export function coiClient(subRequirements) {
  return subRequirements
    ? subRequirements.filter(
        sr => sr.type === 'COI_CLIENT' && sr.name !== 'CLIENT_COMPANY_NAME'
      )
    : [];
}

export function formatRequirement(type, value) {
  switch (type) {
    case 'BUSINESS':
      return formatDays(value);
    default:
      return formatCurrency(value);
  }
}

export function formatCurrency(currency) {
  return currency && currency > 0
    ? `$${abbreviateNumber(currency, { decimalPlaces: 1 })}`
    : '--';
}

export function formatDays(days) {
  return days && days > 0
    ? `${days} ${translator.$t('policies.details.unitOfMeasure.days')}`
    : '--';
}

export function getStatusChipClass(status) {
  const chipClassMap = {
    SUBMITTED: 'gathering-information-chip',
    APPROVED: 'gathering-information-chip',
    OUT_TO_MARKET: 'out-to-market-chip',
    FINALIZE: 'finalize-chip',
    APPLICANT_REVIEW: 'applicant-review-chip',
  };
  return chipClassMap[status] ? chipClassMap[status] : '';
}

export function endorsement(subrequirements) {
  if (subrequirements) {
    return subrequirements.filter(sr => sr.type === 'ENDORSEMENT');
  }
  return [];
}

export function additionalCoverage(subrequirements) {
  if (subrequirements) {
    const additional_coverages = subrequirements.filter(
      sr => sr.type === 'ADDITIONAL_COVERAGE'
    );
    if (additional_coverages.length) {
      let groups = [];
      additional_coverages.forEach(t => {
        const i = groups.map(g => g.name).indexOf(t.name);
        if (i === -1) {
          groups.push({
            name: t.name,
            values: [t],
          });
        } else {
          groups[i].values.push(t);
        }
      });
      return groups;
    }
  }
  return [];
}

export function sublimit(subrequirements) {
  if (subrequirements) {
    return subrequirements.filter(sr => sr.type === 'SUB_LIMIT');
  }
  return [];
}

export function getCreditAnswers(opportunityId) {
  if (
    !localStorage[`${opportunityId}-credit-answers`] ||
    JSON.parse(localStorage[`${opportunityId}-credit-answers`]) === null
  ) {
    localStorage[`${opportunityId}-credit-answers`] = JSON.stringify(
      DEFAULT_CREDIT_ANSWERS
    );
  }
  return JSON.parse(localStorage[`${opportunityId}-credit-answers`]);
}

export function saveCreditAnswers(opportunityId, creditAnswers) {
  localStorage[`${opportunityId}-credit-answers`] = JSON.stringify(
    creditAnswers
  );
}

export function getCollateralAnswers(opportunityId) {
  if (
    !localStorage[`${opportunityId}-collateral-answers`] ||
    JSON.parse(localStorage[`${opportunityId}-collateral-answers`]) === null
  ) {
    localStorage[`${opportunityId}-collateral-answers`] = JSON.stringify(
      DEFAULT_COLLATERAL_ANSWERS
    );
  }
  return JSON.parse(localStorage[`${opportunityId}-collateral-answers`]);
}

export function saveCollateralAnswers(opportunityId, collateralAnswers) {
  localStorage[`${opportunityId}-collateral-answers`] = JSON.stringify(
    collateralAnswers
  );
}

export function requirementPrefix(req) {
  switch (req.insuranceType) {
    case 'BUSINESS':
      return null;
    default:
      return '$';
  }
}

export function requirementSuffix(req) {
  switch (req.insuranceType) {
    case 'BUSINESS':
      return this.$t('addContract.unitOfMeasure.days');
    default:
      return null;
  }
}

export function matchInsurnaceType(fullName) {
  switch (fullName) {
    case 'GeneralLiability':
      return 'GL';
    case 'Umbrella':
      return 'UMB_EXCESS';
    case 'LiquorLiability':
      return 'LIQUOR_LIAB';
    case 'WorkersCompensation':
      return 'WRK_COMP';
    case 'CommercialAuto':
      return 'COM_AUTO';
    case 'EmploymentPracticesLiability':
      return 'EMPL_PRACT_LIAB';
    case 'ProfessionalLiability':
      return 'EO_PROF_LIAB';
  }
}

const DEFAULT_CREDIT_ANSWERS = {
  ltm_rev: null,
  rev_vis: null,
  ltm_net_rev_loan: null,
  twelve_month_net_rev_loan: null,
  ltm_ebitda: null,
  next_year_ebitda: null,
  loan_ent_score: null,
  loan_inv_capital: null,
  quality_equity_backers: null,
  refinance_need: null,
  management_team: null,
  exit_paths: null,
  tam_size: null,
  tam_growth: null,
  easy_capital: null,
  debt_service: null,
  business_plan: null,
  customer_retention: null,
  customer_acq_costs: null,
  sales_channel_maturity: null,
  historical_rev_cagr: null,
  refinancing: null,
};

// leaving this here for now so I don't have to retype this all out if they want the answers to default
// const DEFAULT_CREDIT_ANSWERS = {
//   ltm_rev: 'lt',
//   rev_vis: 'lt',
//   ltm_net_rev_loan: 'lt_one',
//   twelve_month_net_rev_loan: 'lt',
//   ltm_ebitda: 'lt',
//   next_year_ebitda: 'na',
//   loan_ent_score: 'gt',
//   loan_inv_capital: 'gt',
//   quality_equity_backers: 'lt',
//   refinance_need: 'unlikely',
//   management_team: 'thin',
//   exit_paths: 'one_to_five',
//   tam_size: 'gt',
//   tam_growth: 'lt',
//   easy_capital: 'none',
//   debt_service: 'default',
//   business_plan: 'add_cap_req',
//   customer_retention: 'limited',
//   customer_acq_costs: 'high',
//   sales_channel_maturity: 'low',
//   historical_rev_cagr: 'lt',
//   refinancing: 'gt',
// },

const DEFAULT_COLLATERAL_ANSWERS = {
  protected_trade_secrets: null,
  loan_most_recent_ent_score: null,
  historical_rd_investment: null,
  ip_monetization: null,
  ip_software_licenses: null,
  hard_assets: null,
  regulatory_moats: null,
  brand_metrics: null,
  data_assets: null,
  prior_acquisition_offers: null,
  transferable_agreements: null,
  cash: null,
  cash_in_runoff: null,
  loan_arr: null,
};

// const DEFAULT_COLLATERAL_ANSWERS = {
//   protected_trade_secrets: 'lim',
//   loan_most_recent_ent_score: 'gt',
//   historical_rd_investment: 'lt',
//   ip_monetization: 'none',
//   ip_software_licenses: 'limited',
//   hard_assets: 'lt',
//   regulatory_moats: 'low',
//   brand_metrics: 'none',
//   data_assets: 'important',
//   prior_acquisition_offers: 'none',
//   transferable_agreements: 'willing',
//   cash: 'balance',
//   cash_in_runoff: 'limited',
//   loan_arr: 'gt',
// },
