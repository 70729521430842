<template>
  <div class="insurance">
    <h2 class="insurance-name">
      {{ $t('coiReview.validationPanel.commercialAuto.title') }}
    </h2>

    <LabeledInput
      :label="$t('coiReview.validationPanel.policyNumber')"
      :required="true"
    >
      <v-text-field
        v-model="$v.fields.commercialAuto.policyNumber.$model"
        :error-messages="
          insurance.errors($v.fields.commercialAuto.policyNumber)
        "
        validate-on-blur
        outlined
        dense
        type="text"
      />
    </LabeledInput>

    <AutocompleteCarriers
      :insurance="insurance"
      :insurancelob="$v.fields.commercialAuto"
    />

    <LabeledInput
      :label="$t('coiReview.validationPanel.effectiveDate')"
      :required="true"
    >
      <DatePicker
        :date.sync="effectiveDate"
        :error-messages="
          insurance.errors($v.fields.commercialAuto.effectiveDate)
        "
        label=""
        dense
      />
    </LabeledInput>

    <LabeledInput
      :label="$t('coiReview.validationPanel.expirationDate')"
      :required="true"
    >
      <DatePicker
        :date.sync="expirationDate"
        :error-messages="
          insurance.errors($v.fields.commercialAuto.expirationDate)
        "
        label=""
        dense
      />
    </LabeledInput>

    <div v-if="!isMigrated">
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.commercialAuto.caAnyAuto.$model"
          :error-messages="insurance.errors($v.fields.commercialAuto.caAnyAuto)"
          color="aon-peacock"
          :label="$t('coiReview.validationPanel.commercialAuto.anyAuto')"
        />
      </div>

      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.commercialAuto.caOwnedAutosOnly.$model"
          :error-messages="
            insurance.errors($v.fields.commercialAuto.caOwnedAutosOnly)
          "
          color="aon-peacock"
          :label="$t('coiReview.validationPanel.commercialAuto.ownedAutosOnly')"
        />
      </div>

      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.commercialAuto.caHiredAutosOnly.$model"
          :error-messages="
            insurance.errors($v.fields.commercialAuto.caHiredAutosOnly)
          "
          color="aon-peacock"
          :label="$t('coiReview.validationPanel.commercialAuto.hiredAutosOnly')"
        />
      </div>

      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.commercialAuto.caScheduledAutos.$model"
          :error-messages="
            insurance.errors($v.fields.commercialAuto.caScheduledAutos)
          "
          color="aon-peacock"
          :label="$t('coiReview.validationPanel.commercialAuto.scheduledAutos')"
        />
      </div>

      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.commercialAuto.caNonOwnedAutosOnly.$model"
          :error-messages="
            insurance.errors($v.fields.commercialAuto.caNonOwnedAutosOnly)
          "
          color="aon-peacock"
          :label="
            $t('coiReview.validationPanel.commercialAuto.nonOwnedAutosOnly')
          "
        />
      </div>
    </div>

    <label class="comAuto-label">
      {{ $t('coiReview.validationPanel.commercialAuto.endorsementsLabel') }}
    </label>

    <div v-if="this.$v.fields.isMigrated.$model">
      <Endorsement
        :options="clientList"
        :label="$t('endorsements.additionalInsured')"
        :display-options="this.$v.fields.commercialAuto.caAdditionalInsured"
        @updateList="updateAdditionalInsured"
      />

      <Endorsement
        :options="clientList"
        :label="$t('endorsements.waiverOfSubrogation')"
        :display-options="this.$v.fields.commercialAuto.waiverOfSubrogation"
        @updateList="updateWaiverOfSubrogation"
      />

      <Endorsement
        :options="clientList"
        :label="$t('endorsements.primaryNonContributory')"
        :display-options="this.$v.fields.commercialAuto.primaryNonContributory"
        @updateList="updatePrimaryNonContributory"
      />
    </div>
    <div v-else>
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.commercialAuto.caAdditionalInsured.$model"
          :error-messages="
            insurance.errors($v.fields.commercialAuto.caAdditionalInsured)
          "
          color="aon-peacock"
          :label="
            $t('coiReview.validationPanel.commercialAuto.additionalInsured')
          "
        />
      </div>

      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.commercialAuto.waiverOfSubrogation.$model"
          :error-messages="
            insurance.errors($v.fields.commercialAuto.waiverOfSubrogation)
          "
          color="aon-peacock"
          :label="
            $t('coiReview.validationPanel.commercialAuto.waiverOfSubrogation')
          "
        />
      </div>

      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.commercialAuto.primaryNonContributory.$model"
          :error-messages="
            insurance.errors($v.fields.commercialAuto.primaryNonContributory)
          "
          color="aon-peacock"
          :label="
            $t(
              'coiReview.validationPanel.commercialAuto.primaryNonContributory'
            )
          "
        />
      </div>
    </div>

    <div v-if="!isMigrated">
      <LabeledInput
        :label="
          $t('coiReview.validationPanel.commercialAuto.combinedSingleLimit')
        "
      >
        <v-text-field
          v-model.number="$v.fields.commercialAuto.caCombinedSingleLimit.$model"
          :error-messages="
            insurance.errors($v.fields.commercialAuto.caCombinedSingleLimit)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="
          $t('coiReview.validationPanel.commercialAuto.bodilyInjuryPerPerson')
        "
      >
        <v-text-field
          v-model.number="
            $v.fields.commercialAuto.caBodilyInjuryPerPerson.$model
          "
          :error-messages="
            insurance.errors($v.fields.commercialAuto.caBodilyInjuryPerPerson)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="
          $t('coiReview.validationPanel.commercialAuto.bodilyInjuryPerAccident')
        "
      >
        <v-text-field
          v-model.number="
            $v.fields.commercialAuto.caBodilyInjuryPerAccident.$model
          "
          :error-messages="
            insurance.errors($v.fields.commercialAuto.caBodilyInjuryPerAccident)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="
          $t(
            'coiReview.validationPanel.commercialAuto.propertyDamagePerAccident'
          )
        "
      >
        <v-text-field
          v-model.number="
            $v.fields.commercialAuto.caPropertyDamagePerAccident.$model
          "
          :error-messages="
            insurance.errors(
              $v.fields.commercialAuto.caPropertyDamagePerAccident
            )
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>
    </div>
  </div>
</template>

<script>
import AutocompleteCarriers from '@/components/CoiReview/AutocompleteCarriers';
import Endorsement from '@/components/Endorsement';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';

export default {
  name: 'CommercialAuto',
  components: {
    AutocompleteCarriers,
    Endorsement,
    DatePicker,
    LabeledInput,
  },
  props: {
    insurance: {
      type: Object,
      default: () => {},
    },
    clientList: {
      type: Array,
      default: () => [],
    },
    isMigrated: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    $v() {
      return this.insurance.$v;
    },
    effectiveDate: {
      get() {
        return this.$v.fields.commercialAuto.effectiveDate.$model
          ? this.$moment
              .utc(this.$v.fields.commercialAuto.effectiveDate.$model)
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        return (this.$v.fields.commercialAuto.effectiveDate.$model = value
          ? this.$moment.utc(value).format()
          : null);
      },
    },
    expirationDate: {
      get() {
        return this.$v.fields.commercialAuto.expirationDate.$model
          ? this.$moment
              .utc(this.$v.fields.commercialAuto.expirationDate.$model)
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$v.fields.commercialAuto.expirationDate.$model = value
          ? this.$moment.utc(value).format()
          : null;
      },
    },
  },
  methods: {
    updateAdditionalInsured({ selectedValues }) {
      this.$v.fields.commercialAuto.endorsements.additionalInsured.$model = selectedValues;
    },
    updateWaiverOfSubrogation({ selectedValues }) {
      this.$v.fields.commercialAuto.endorsements.waiverOfSubrogation.$model = selectedValues;
    },
    updatePrimaryNonContributory({ selectedValues }) {
      this.$v.fields.commercialAuto.endorsements.primaryNonContributory.$model = selectedValues;
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance {
  border-top: $border-gray-06;
  border-bottom: $border-gray-06;
  padding: 20px;

  &-name {
    color: #46535e;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 18px;
  }
}

.comAuto-label {
  display: inline-block;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #46535e;
  margin: 14px 0;
}

.checkbox-container {
  display: flex;
  align-items: center;

  & .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
