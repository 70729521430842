<template>
  <div class="main-container">
    <v-navigation-drawer absolute permanent right>
      <v-form>
        <LabeledInput
          :required="true"
          :label="$t(`coiReview.validationPanel.insuredLabel`)"
        >
          <v-autocomplete
            v-model="$v.fields.vendor.$model"
            :error-messages="policy.errors($v.fields.vendor)"
            dense
            outlined
            clearable
            :items="vendorList"
            item-text="vendorName"
            return-object
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t(`coiReview.validationPanel.vendorPlaceholder`)"
          >
          </v-autocomplete>
        </LabeledInput>

        <LabeledInput
          :label="$t('coiReview.validationPanel.broker')"
          :required="true"
          :wrapped="false"
        >
          <v-radio-group
            v-model="$v.fields.brokeredBy.$model"
            :error-messages="policy.errors($v.fields.brokeredBy)"
            dense
          >
            <v-radio
              :label="$t('coiReview.validationPanel.brokeredBy.aon')"
              value="aon"
            ></v-radio>
            <v-radio
              :label="$t('coiReview.validationPanel.brokeredBy.coverwallet')"
              value="coverwallet"
            ></v-radio>
            <v-radio
              :label="$t('coiReview.validationPanel.brokeredBy.other')"
              value="other"
            ></v-radio>
          </v-radio-group>
        </LabeledInput>

        <div v-if="$v.fields.brokeredBy.$model === 'other'">
          <LabeledInput
            :label="$t('coiReview.validationPanel.brokerTextField')"
            :required="true"
          >
            <v-text-field
              v-model="$v.fields.brokerName.$model"
              :error-messages="policy.errors($v.fields.brokerName)"
              dense
              outlined
              :placeholder="$t('coiReview.validationPanel.brokerTextField')"
            />
          </LabeledInput>
        </div>

        <LabeledInput
          :required="true"
          :label="$t(`coiReview.validationPanel.certHolderLabel`)"
        >
          <v-autocomplete
            v-model="$v.fields.clientName.$model"
            :error-messages="policy.errors($v.fields.clientName)"
            dense
            outlined
            clearable
            :items="clientList"
            item-text="companyName"
            item-value="companyName"
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t(`coiReview.validationPanel.clientPlaceholder`)"
          >
          </v-autocomplete>
        </LabeledInput>

        <div v-if="!isMigrated" class="checkbox-container">
          <v-checkbox
            v-model="$v.fields.selectedCertificateHolder.$model"
            :error-messages="policy.errors($v.fields.selectedCertificateHolder)"
            color="aon-peacock"
            value="CERTIFICATE_HOLDER"
            :label="$t('coiReview.validationPanel.certificateHolder')"
          />
        </div>

        <LabeledInput
          :label="$t('insurancePolicyModal.territory')"
          :required="true"
        >
          <v-autocomplete
            v-model="$v.fields.territory.$model"
            :error-messages="policy.errors($v.fields.territory)"
            :items="policy.territoryTypeOptions"
            :menu-props="'offset-y'"
            dense
            outlined
          />
        </LabeledInput>

        <LabeledInput
          :label="$t('insurancePolicyModal.policyType')"
          :required="true"
          :wrapped="false"
        >
          <v-radio-group
            v-model="$v.fields.policyType.$model"
            class="mt-0"
            :error-messages="policy.errors($v.fields.policyType)"
            row
          >
            <v-radio
              color="aon-teal"
              value="Broad"
              :label="$t('insurancePolicyModal.broad')"
            />
            <v-radio
              color="aon-teal"
              value="Exclusive"
              :label="$t('insurancePolicyModal.clientExclusive')"
            />
          </v-radio-group>
        </LabeledInput>

        <v-divider></v-divider>

        <div v-if="workersComp">
          <WorkersCompensation
            :insurance="policy"
            :is-migrated="isMigrated"
            :client-list="endorsementList"
          />
        </div>

        <div v-if="umbrella">
          <Umbrella
            :insurance="policy"
            :is-migrated="isMigrated"
            :client-list="endorsementList"
          />
        </div>

        <div v-if="generalLiability">
          <GeneralLiability
            :insurance="policy"
            :is-migrated="isMigrated"
            :client-list="endorsementList"
          />
        </div>

        <div v-if="liquorLiability">
          <LiquorLiability
            :insurance="policy"
            :is-migrated="isMigrated"
            :client-list="endorsementList"
          />
        </div>

        <div v-if="commercialAuto">
          <CommercialAuto
            :insurance="policy"
            :is-migrated="isMigrated"
            :client-list="endorsementList"
          />
        </div>

        <div v-if="empPracticesLiability">
          <EmploymentPracticesLiability
            :insurance="policy"
            :is-migrated="isMigrated"
          />
        </div>

        <div v-if="profLiability">
          <EOProfessionalLiability
            :insurance="policy"
            :is-migrated="isMigrated"
            :client-list="endorsementList"
          />
        </div>

        <v-divider></v-divider>

        <div class="button-container mt-auto">
          <v-row class="mt-4 mb-4" dense>
            <AppButton
              class="mx-5"
              full-width
              :loading="saving"
              @click="saveReview({ status: 'approved' })"
            >
              {{ $t('coiReview.validationPanel.btnApprove') }}
            </AppButton>
          </v-row>
          <v-row class="mt-5" dense>
            <AppButton
              class="mx-5 report-issue"
              full-width
              @click="showRejectDialog = true"
            >
              {{ $t('coiReview.validationPanel.btnReportAnIssue') }}
            </AppButton>
          </v-row>
        </div>
      </v-form>
    </v-navigation-drawer>
    <CoiRejectDialog
      v-model="showRejectDialog"
      @close="showRejectDialog = false"
      @submit="saveReview"
    />
  </div>
</template>

<script>
import * as api from '@/api/insurancePolicies';
import * as vendorApi from '@/api/vendors';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import WorkersCompensation from '@/components/CoiReview/policies/WorkersCompensation';
import Umbrella from '@/components/CoiReview/policies/Umbrella';
import GeneralLiability from '@/components/CoiReview/policies/GeneralLiability';
import EmploymentPracticesLiability from '@/components/CoiReview/policies/EmploymentPracticesLiability';
import LiquorLiability from '@/components/CoiReview/policies/LiquorLiability';
import CoiRejectDialog from '@/components/CoiReview/CoiRejectDialog';
import CommercialAuto from '@/components/CoiReview/policies/CommercialAuto';
import EOProfessionalLiability from '@/components/CoiReview/policies/EOProfessionalLiability';

export default {
  name: 'CoiDetails',
  components: {
    LabeledInput,
    WorkersCompensation,
    Umbrella,
    GeneralLiability,
    LiquorLiability,
    CoiRejectDialog,
    CommercialAuto,
    EmploymentPracticesLiability,
    EOProfessionalLiability,
  },
  props: {
    policy: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showRejectDialog: false,
      saving: false,
      clientList: [],
      vendorList: [],
    };
  },
  computed: {
    $v() {
      return this.policy?.$v;
    },
    vendor() {
      return this.$v?.fields.vendor.$model;
    },
    insuranceType() {
      return this.$v?.fields.insuranceType?.$model;
    },
    workersComp() {
      return this.$v.fields.workersCompensation.$model;
    },
    umbrella() {
      return this.$v.fields.umbrella.$model;
    },
    generalLiability() {
      return this.$v.fields.generalLiability.$model;
    },
    liquorLiability() {
      return this.$v.fields.liquorLiability.$model;
    },
    commercialAuto() {
      return this.$v.fields.commercialAuto.$model;
    },
    empPracticesLiability() {
      return this.$v.fields.employmentPracticesLiability.$model;
    },
    profLiability() {
      return this.$v.fields.professionalLiability.$model;
    },
    isMigrated: {
      get() {
        return this.$v.fields.isMigrated.$model;
      },
      set(value) {
        this.$v.fields.isMigrated.$model = value;
      },
    },
    endorsementList() {
      return [
        {
          clientName: 'Blanket',
          clientId: 'Blanket',
          companyName: 'Blanket',
        },
        ...this.clientList,
      ];
    },
  },
  watch: {
    async vendor(value) {
      try {
        this.isMigrated = value ? value.migrated : false;
        const response = await vendorApi.getVendorClients(value.vendorId);
        this.clientList = response.data.results.map(client => {
          return {
            clientName: client.name,
            clientId: client.companyId,
            companyName: client.companyName,
          };
        });
      } catch (err) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: 'Failed to retrieve the vendor clients',
        });
      }
    },
  },
  async mounted() {
    const vendors = await api.getVendors();
    this.vendorList = vendors.data
      .filter(vendor => vendor.name)
      .map(vendor => {
        return {
          vendorId: vendor.id,
          vendorName: vendor.name,
          tenantId: vendor.companyId,
          migrated: vendor.migrated,
        };
      });
  },
  methods: {
    saveReview({ status, rejectionReason }) {
      this.showRejectDialog = false;
      this.$emit('save', { status, rejectionReason });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  .v-navigation-drawer {
    padding: 20px 0;
    width: 300px !important;

    & .LabeledInput {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  & .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}

.app-button.report-issue {
  border: 1px solid #262836 !important;
  background: #fff !important;
  color: #262836 !important;
}
</style>
