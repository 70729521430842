import { translator } from '@aon/cfs-utils/lib/translation';
import { VueForm, Model } from '@aon/cfs-forms';
import {
  required,
  maxValue,
  helpers,
  minValue,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import moment from 'moment';
import { matchInsurnaceType } from '@/lib/helpers';

const maxDollarAmount = 1000000000;

const initialModel = {
  vendor: {
    default: null,
    validations: { required },
  },
  isMigrated: {
    default: false,
  },
  brokeredBy: {
    default: 'other',
    validations: { required },
  },
  brokerName: {
    default: null,
  },
  clientName: {
    default: null,
    validations: {
      required,
    },
  },
  selectedCertificateHolder: {
    default: false,
  },
  territory: {
    default: 'USA',
    validations: { required },
  },
  policyType: {
    default: 'Broad',
    validations: { required },
  },
  generalLiability: {
    default: null,
  },
  umbrella: {
    default: null,
  },
  liquorLiability: {
    default: null,
  },
  workersCompensation: {
    default: null,
  },
  commercialAuto: {
    default: null,
  },
  employmentPracticesLiability: {
    default: null,
  },
  professionalLiability: {
    default: null,
  },
};

const umbrellaModel = {
  insuranceType: {
    default: 'UMB_EXCESS',
  },
  policyNumber: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(100),
    },
  },
  carrier: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  carrierAonId: {
    default: null,
    validations: {},
  },
  carrierNaic: {
    default: null,
    validations: {},
  },
  effectiveDate: {
    default: null,
    validations: { required },
  },
  expirationDate: {
    default: null,
    validations: {
      required,
      greaterThanToday: value => {
        if (!helpers.req(value)) return true;

        const today = moment.utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(today);
      },
      greaterThanEffectiveDate: (value, fields) => {
        if (!helpers.req(value) || !helpers.req(fields.effectiveDate)) {
          return true;
        }

        const effectiveDate = moment.utc(fields.effectiveDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(effectiveDate);
      },
    },
  },
  umUmbrellaPresent: {
    default: null,
  },
  umExcessPresent: {
    default: null,
  },
  additionalInsured: {
    default: null,
  },
  waiverOfSubrogation: {
    default: null,
  },
  primaryNonContributory: {
    default: null,
  },
  eachOccurrence: {
    default: 0,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  umAggregate: {
    default: 0,
    validations: {
      mustHaveCoverage: (value, fields) =>
        value !== undefined ||
        value !== null ||
        fields.eachOccurrence !== undefined ||
        fields.eachOccurrence !== null,
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
      required,
    },
  },
  endorsements: {
    additionalInsured: {
      default: null,
    },
    waiverOfSubrogation: {
      default: null,
    },
    primaryNonContributory: {
      default: null,
    },
  },
};

const comAutoModel = {
  insuranceType: {
    default: 'COM_AUTO',
  },
  policyNumber: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(100),
    },
  },
  carrier: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  carrierAonId: {
    default: null,
    validations: {},
  },
  carrierNaic: {
    default: null,
    validations: {},
  },
  effectiveDate: {
    default: null,
    validations: { required },
  },
  expirationDate: {
    default: null,
    validations: {
      required,
      greaterThanToday: value => {
        if (!helpers.req(value)) return true;

        const today = moment.utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(today);
      },
      greaterThanEffectiveDate: (value, fields) => {
        if (!helpers.req(value) || !helpers.req(fields.effectiveDate)) {
          return true;
        }

        const effectiveDate = moment.utc(fields.effectiveDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(effectiveDate);
      },
    },
  },
  caAnyAuto: {
    default: null,
  },
  caOwnedAutosOnly: {
    default: null,
  },
  caHiredAutosOnly: {
    default: null,
  },
  caScheduledAutos: {
    default: null,
  },
  caNonOwnedAutosOnly: {
    default: null,
  },
  caAdditionalInsured: {
    default: null,
  },
  waiverOfSubrogation: {
    default: null,
  },
  primaryNonContributory: {
    default: null,
  },
  caCombinedSingleLimit: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  caBodilyInjuryPerPerson: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  caBodilyInjuryPerAccident: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  caPropertyDamagePerAccident: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  endorsements: {
    additionalInsured: {
      default: null,
    },
    waiverOfSubrogation: {
      default: null,
    },
    primaryNonContributory: {
      default: null,
    },
  },
};

const llModel = {
  insuranceType: {
    default: 'LIQUOR_LIAB',
  },
  policyNumber: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(100),
    },
  },
  carrier: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  carrierAonId: {
    default: null,
    validations: {},
  },
  carrierNaic: {
    default: null,
    validations: {},
  },
  effectiveDate: {
    default: null,
    validations: { required },
  },
  expirationDate: {
    default: null,
    validations: {
      required,
      greaterThanToday: value => {
        if (!helpers.req(value)) return true;

        const today = moment.utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(today);
      },
      greaterThanEffectiveDate: (value, fields) => {
        if (!helpers.req(value) || !helpers.req(fields.effectiveDate)) {
          return true;
        }

        const effectiveDate = moment.utc(fields.effectiveDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(effectiveDate);
      },
    },
  },
  additionalInsured: {
    default: null,
  },
  llEachCommonCause: {
    default: 0,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
      required,
    },
  },
  aggregate: {
    default: 0,
    validations: {
      mustHaveCoverage: (value, fields) =>
        value !== undefined ||
        value !== null ||
        fields.llEachCommonCause !== undefined ||
        fields.llEachCommonCause !== null,
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
      required,
    },
  },
  endorsements: {
    additionalInsured: {
      default: null,
    },
  },
};

const glModel = {
  insuranceType: {
    default: 'GL',
  },
  policyNumber: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(100),
    },
  },
  carrier: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  carrierAonId: {
    default: null,
    validations: {},
  },
  carrierNaic: {
    default: null,
    validations: {},
  },
  effectiveDate: {
    default: null,
    validations: { required },
  },
  expirationDate: {
    default: null,
    validations: {
      required,
      greaterThanToday: value => {
        if (!helpers.req(value)) return true;

        const today = moment.utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(today);
      },
      greaterThanEffectiveDate: (value, fields) => {
        if (!helpers.req(value) || !helpers.req(fields.effectiveDate)) {
          return true;
        }

        const effectiveDate = moment.utc(fields.effectiveDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(effectiveDate);
      },
    },
  },
  eachOccurrence: {
    default: 0,
    validations: {
      mustHaveCoverage: (value, fields) =>
        value !== undefined ||
        value !== null ||
        fields.coverage !== undefined ||
        fields.coverage !== null,
      minValue: minValue(-1),
      maxValue: maxValue(maxDollarAmount),
      required,
    },
  },
  aggregate: {
    default: 0,
    validations: {
      mustHaveCoverage: (value, fields) =>
        value !== undefined ||
        value !== null ||
        fields.eachOccurrence !== undefined ||
        fields.eachOccurrence !== null,
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
      required,
    },
  },
  deductible: {
    default: 0,
    validations: {
      minValue: minValue(0),
      maxValue: (value, fields) =>
        maxValue(
          helpers.req(fields.aggregate) &&
            fields.aggregate < maxDollarAmount &&
            helpers.req(fields.eachOccurrence) &&
            fields.eachOccurrence < maxDollarAmount
            ? Math.max(fields.aggregate, fields.eachOccurrence)
            : helpers.req(fields.aggregate) &&
              fields.aggregate < maxDollarAmount
            ? fields.aggregate
            : helpers.req(fields.eachOccurrence) &&
              fields.eachOccurrence < maxDollarAmount
            ? fields.eachOccurrence
            : maxDollarAmount
        )(value),
      required,
    },
  },
  additionalInsured: {
    default: null,
  },
  waiverOfSubrogation: {
    default: null,
  },
  primaryNonContributory: {
    default: null,
  },
  damageToRentedPremises: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  productsComp: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  personalAdvInjury: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  endorsements: {
    additionalInsured: {
      default: null,
    },
    waiverOfSubrogation: {
      default: null,
    },
    primaryNonContributory: {
      default: null,
    },
  },
  perPolicy: {
    default: null,
  },
  perProject: {
    default: null,
  },
  perLocation: {
    default: null,
  },
  coverOccurrence: {
    default: null,
  },
  coverClaimsMade: {
    default: null,
  },
};

const wcModel = {
  insuranceType: {
    default: 'WRK_COMP',
  },
  policyNumber: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(100),
    },
  },
  carrier: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  carrierAonId: {
    default: null,
    validations: {},
  },
  carrierNaic: {
    default: null,
    validations: {},
  },
  effectiveDate: {
    default: null,
    validations: { required },
  },
  expirationDate: {
    default: null,
    validations: {
      required,
      greaterThanToday: value => {
        if (!helpers.req(value)) return true;

        const today = moment.utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(today);
      },
      greaterThanEffectiveDate: (value, fields) => {
        if (!helpers.req(value) || !helpers.req(fields.effectiveDate)) {
          return true;
        }

        const effectiveDate = moment.utc(fields.effectiveDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(effectiveDate);
      },
    },
  },
  perStatute: {
    default: null,
  },
  waiverOfSubrogation: {
    default: null,
  },
  elEachAccident: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  elDiseaseEaEmployee: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  elDiseasePolicyLimit: {
    default: null,
    validations: {
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
    },
  },
  endorsements: {
    waiverOfSubrogation: {
      default: null,
    },
  },
};

const elModel = {
  insuranceType: {
    default: 'EMPL_PRACT_LIAB',
  },
  policyNumber: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(100),
    },
  },
  carrier: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  carrierAonId: {
    default: null,
    validations: {},
  },
  carrierNaic: {
    default: null,
    validations: {},
  },
  effectiveDate: {
    default: null,
    validations: { required },
  },
  expirationDate: {
    default: null,
    validations: {
      required,
      greaterThanToday: value => {
        if (!helpers.req(value)) return true;

        const today = moment.utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(today);
      },
      greaterThanEffectiveDate: (value, fields) => {
        if (!helpers.req(value) || !helpers.req(fields.effectiveDate)) {
          return true;
        }

        const effectiveDate = moment.utc(fields.effectiveDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(effectiveDate);
      },
    },
  },
  aggregate: {
    default: null,
    validations: {
      mustHaveCoverage: (value, fields) =>
        value !== undefined ||
        value !== null ||
        fields.eachOccurrence !== undefined ||
        fields.eachOccurrence !== null,
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
      required,
    },
  },
};

const plModel = {
  insuranceType: {
    default: 'EO_PROF_LIAB',
  },
  policyNumber: {
    default: null,
    validations: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(100),
    },
  },
  carrier: {
    default: null,
    validations: { required, maxLength: maxLength(100) },
  },
  carrierAonId: {
    default: null,
    validations: {},
  },
  carrierNaic: {
    default: null,
    validations: {},
  },
  effectiveDate: {
    default: null,
    validations: { required },
  },
  expirationDate: {
    default: null,
    validations: {
      required,
      greaterThanToday: value => {
        if (!helpers.req(value)) return true;

        const today = moment.utc().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(today);
      },
      greaterThanEffectiveDate: (value, fields) => {
        if (!helpers.req(value) || !helpers.req(fields.effectiveDate)) {
          return true;
        }

        const effectiveDate = moment.utc(fields.effectiveDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const expirationDate = moment.utc(value).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

        return expirationDate.isAfter(effectiveDate);
      },
    },
  },
  eachOccurrence: {
    default: 0,
    validations: {
      mustHaveCoverage: (value, fields) =>
        value !== undefined ||
        value !== null ||
        fields.coverage !== undefined ||
        fields.coverage !== null,
      minValue: minValue(-1),
      maxValue: maxValue(maxDollarAmount),
      required,
    },
  },
  aggregate: {
    default: 0,
    validations: {
      mustHaveCoverage: (value, fields) =>
        value !== undefined ||
        value !== null ||
        fields.eachOccurrence !== undefined ||
        fields.eachOccurrence !== null,
      minValue: minValue(0),
      maxValue: maxValue(maxDollarAmount),
      required,
    },
  },
  additionalInsured: {
    default: null,
  },
  endorsements: {
    additionalInsured: {
      default: null,
    },
  },
};

export const policyTypes = Object.values(
  translator.$t(`insurancePolicyModal.policyTypes`)
);
export const policyTypeKeys = Object.keys(
  translator.$t(`insurancePolicyModal.policyTypes`)
);
export const countryTypes = Object.values(translator.$t(`countries.countries`));
export const countryKeys = Object.keys(translator.$t(`countries.countries`));

export const territoryTypeOptions = new Array(countryTypes.length)
  .fill()
  .map((_, index) => ({
    value: countryKeys[index],
    text: countryTypes[index].name,
  }));

export default function CoiReviewForm(initialValues, coverageList = []) {
  const policyTypeList = coverageList.map(policy =>
    matchInsurnaceType(policy.policyType)
  );

  let newModel = {
    ...initialModel,
  };

  if (policyTypeList.includes('GL')) {
    newModel = {
      ...newModel,
      generalLiability: glModel,
    };
  }

  if (policyTypeList.includes('LIQUOR_LIAB')) {
    newModel = {
      ...newModel,
      liquorLiability: llModel,
    };
  }

  if (policyTypeList.includes('WRK_COMP')) {
    newModel = {
      ...newModel,
      workersCompensation: wcModel,
    };
  }

  if (policyTypeList.includes('UMB_EXCESS')) {
    newModel = {
      ...newModel,
      umbrella: umbrellaModel,
    };
  }

  if (policyTypeList.includes('COM_AUTO')) {
    newModel = {
      ...newModel,
      commercialAuto: comAutoModel,
    };
  }

  if (policyTypeList.includes('EMPL_PRACT_LIAB')) {
    newModel = {
      ...newModel,
      employmentPracticesLiability: elModel,
    };
  }

  if (policyTypeList.includes('EO_PROF_LIAB')) {
    newModel = {
      ...newModel,
      professionalLiability: plModel,
    };
  }

  let model = new Model({ ...newModel });

  return VueForm({
    model,
    initialValues,
    customErrorMessages: {
      greaterThanToday: translator.$t('insurancePolicyModal.greaterThanToday'),
      greaterThanEffectiveDate: translator.$t(
        'insurancePolicyModal.greaterThanEffectiveDate'
      ),
      mustHaveCoverage: translator.$t('insurancePolicyModal.mustHaveCoverage'),
    },
    data: {
      territoryTypeOptions,
    },
    methods: {
      getFormValues() {
        const values = this._getFormValues();

        values.effectiveDate = moment.utc(values.effectiveDate).format();
        values.expirationDate = moment.utc(values.expirationDate).format();

        return values;
      },
    },
  });
}
