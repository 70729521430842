<template>
  <div class="endorsement">
    <v-checkbox
      v-model="displayOptions.$model"
      color="aon-peacock"
      :label="label"
    />

    <div v-show="displayOptions.$model" class="endorsement-select">
      <EndorsementOptions
        v-for="element in elements"
        :id="element.id"
        :key="element.id"
        :options="options"
        @onChange="onChange"
      />
      <button class="endorsement__btn" @click="addElement($event)">
        {{ $t('endorsements.buttonAdd') }}
      </button>
    </div>
  </div>
</template>

<script>
import EndorsementOptions from '@/components/EndorsementOptions';

export default {
  name: 'Endorsement',
  components: {
    EndorsementOptions,
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    displayOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      elements: [
        {
          id: window.crypto.randomUUID(),
          companyName: '',
        },
      ],
    };
  },
  methods: {
    addElement(event) {
      event.preventDefault();
      const newElement = {
        id: window.crypto.randomUUID(),
        companyName: '',
      };
      this.elements.push(newElement);
    },
    onChange({ id, companyName }) {
      const elementIndex = this.elements.findIndex(
        element => element.id === id
      );
      this.elements[elementIndex].companyName = companyName;
      this.$emit('updateList', { selectedValues: this.elements });
    },
  },
};
</script>

<style lang="scss" scoped>
.endorsement {
  & .v-input--selection-controls {
    margin: 0;
    padding-top: 0;
  }
}

.endorsement-select {
  display: flex;
  flex-direction: column;
  transform: translateY(-12px);
}

.endorsement__btn {
  color: #007585;
  text-decoration: underline;
  transform: translateY(-20px);
}
</style>
