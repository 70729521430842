<template>
  <div class="coi-review-dialog-content">
    <div v-if="policy.id" class="title-bar elevation-2">
      <v-row no-gutters>
        <v-col cols="4">
          <span class="magnify-icons pb-1 ml-4" @click="setPdfWidth(true)">
            <i class="fa fa-search-plus fa-lg aon-peacock--text mt-3"></i>
          </span>
          <span class="magnify-icons pb-1 ml-3" @click="setPdfWidth(false)">
            <i class="fa fa-search-minus fa-lg aon-peacock--text mt-3"></i>
          </span>
        </v-col>
        <v-col cols="4">
          <v-row class="justify-center" no-gutters>
            <span class="pt-3 aon-peacock--text">
              {{ fileName || $t('coiReview.noCoi') }}
            </span>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <template v-if="loading || policyListLoading">
      <Spinner class="absolute-center" />
    </template>
    <template v-else>
      <div
        v-if="!loading"
        class="pdf-content mx-auto my-5"
        :style="`width: ${size}%;`"
      >
        <template v-if="coi !== undefined">
          <pdf
            v-for="i in numPages"
            :key="`${fileName}-${i}`"
            :src="coi"
            :page="i"
          />
        </template>
      </div>
      <CoiDetails :policy="coiForm" @save="saveReview" />
      <CoiRejectDialog
        v-model="showRejectDialog"
        @close="showRejectDialog = false"
        @submit="saveReview"
      />
    </template>
  </div>
</template>

<script>
import * as api from '@/api/insurancePolicies';
import currencyFilter from '@/filters/currency';
import { getDateFromUTC } from '@aon/cfs-utils';
import pdf from 'vue-pdf';
import { mapState } from 'vuex';
import CoiReviewForm from '@/lib/forms/coi-review';
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';
import CoiRejectDialog from '@/components/CoiReview/CoiRejectDialog';
import CoiDetails from '@/components/CoiReview/CoiDetails';
import { formatRequirement } from '@/lib/helpers';

const base64ToUint8Array = base64 => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

const loadedPdfs = {};

export default {
  name: 'CoiReviewDialog',
  components: {
    CoiRejectDialog,
    pdf,
    CoiDetails,
  },
  mixins: [scrollToFirstError],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    policy: {
      type: Object,
      default: () => {},
    },
    extractedCoverages: {
      type: Array,
      default: () => [],
    },
    document: {
      type: Object,
      default: () => {},
    },
    policyListLoading: {
      type: Boolean,
      default: true,
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      numPages: 0,
      coi: '',
      size: 95,
      fileName: null,
      showRejectDialog: false,
      coiForm: new CoiReviewForm(this.policy, this.extractedCoverages),
    };
  },
  computed: {
    ...mapState('insurancePolicy', {
      documentId: state => state.documentId,
    }),
    $v() {
      return this.coiForm.$v;
    },
    subrequirements() {
      var iters = this.$v.fields.subrequirements.$each.$iter;
      return Object.keys(iters)
        .sort((a, b) => {
          //sublimits ->  additional coverage -> endorsements
          if (iters[a].$model.type === 'SUB_LIMIT') return -1;
          if (iters[b].$model.type === 'SUB_LIMIT') return 1;
          if (iters[a].$model.type > iters[b].$model.type) return 1;
          else return -1;
        })
        .map(k => iters[k])
        .filter(
          sr =>
            sr.$model.type != 'MISCELLANEOUS' &&
            sr.$model.name != 'CLIENT_COI_NOTE'
        );
    },
    policyFields() {
      return [
        { name: 'carrier', value: this.policy.carrier },
        {
          name: 'insuranceType',
          value: this.$t(`mappings.insurance.${this.policy.insuranceType}`),
        },
        { name: 'policyType', value: this.policy.policyType },
        {
          name: 'effectiveDate',
          value: this.formatDate(this.policy.effectiveDate),
        },
        {
          name: 'expirationDate',
          value: this.formatDate(this.policy.expirationDate),
        },
        {
          name: 'coverage',
          value: this.formatRequirement(
            this.policy.insuranceType,
            this.policy.coverage
          ),
        },
        {
          name: 'perOccurrenceCoverage',
          value: this.formatRequirement(
            this.policy.insuranceType,
            this.policy.perOccurrenceCoverage
          ),
        },
        {
          name: 'deductible',
          value: this.formatRequirement(
            this.policy.insuranceType,
            this.policy.deductible
          ),
        },
      ];
    },
    coiNote() {
      return this.policy.subrequirements.find(
        sr => sr.type === 'MISCELLANEOUS' && sr.name === 'CLIENT_COI_NOTE'
      );
    },
  },
  watch: {
    async document(value) {
      this.size = 95;
      this.$emit('setPolicySaving', false);
      if (!value) return;
      this.coi = undefined;
      this.fileName = this.$t('coiReview.coiLoading');
      this.loading = true;
      try {
        await this.$store.dispatch(
          'insurancePolicy/getDocumentId',
          this.document.fileId
        );
        const docResponse = await api.getDocument(this.documentId);

        const converted = await this.blobToBase64(docResponse.data);
        const docData = base64ToUint8Array(converted.split(',')[1]);
        const doc = await pdf.createLoadingTask(docData).promise;
        loadedPdfs[this.document.fileId] = {
          data: docData,
          fileName,
          pages: doc.numPages,
        };

        const { data, fileName, pages } = loadedPdfs[this.document.fileId];
        this.numPages = pages;
        this.coi = data;
        this.fileName = this.policy.fileName;
      } catch (err) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: 'Failed to retrieve the document',
        });
      }
      this.loading = false;
    },
    policy() {
      this.coiForm = new CoiReviewForm(this.policy, this.extractedCoverages);
    },
    loading(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    formatRequirement,
    async blobToBase64(blob) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, _) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(blob);
        fileReader.onloadend = function() {
          resolve(fileReader.result);
        };
      });
    },
    valueClass(fieldName) {
      if (this.coiForm.errors(this.$v.fields[fieldName])) {
        return 'error--text';
      }
      if (this.saving || this.coi === undefined) {
        return 'aon-gray-02--text';
      }
    },
    setPdfWidth(larger) {
      this.size = larger
        ? Math.min(this.size + 10, 150)
        : Math.max(this.size - 10, 25);
    },
    saveReview({ status, rejectionReason }) {
      if (status === 'approved') {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.scrollToFirstError();
          return;
        }
      } else {
        this.showRejectDialog = false;
      }
      this.$emit('approved', {
        status,
        rejectionReason,
        updatedPolicy: this.coiForm.getFormValues(),
      });
      this.$emit('setPolicySaving', true);
    },
    close() {
      this.$emit('close');
    },
    formatDate(date) {
      return getDateFromUTC(date);
    },
    formatCoverage(coverage) {
      return coverage ? currencyFilter(coverage) : '--';
    },
    getSubRequirementDisplayValue(subRequirement) {
      if (subRequirement.type === 'SUB_LIMIT') {
        return this.$t(
          `policies.SUB_LIMIT.${this.policy.insuranceType}.${subRequirement.name}`
        );
      } else if (
        subRequirement.type === 'ADDITIONAL_COVERAGE' &&
        subRequirement.value
      ) {
        return this.$t(
          `policies.ADDITIONAL_COVERAGE.${subRequirement.name}.${subRequirement.value}`
        );
      } else if (subRequirement.type === 'ADDITIONAL_COVERAGE') {
        return subRequirement.name === 'ACTS_OF_TERROR'
          ? this.$t(
              `policies.${subRequirement.type}.${subRequirement.name}.ACTS_OF_TERROR`
            )
          : this.$t(`policies.${subRequirement.type}.${subRequirement.name}`);
      } else if (subRequirement.type === 'COI_CLIENT') {
        return subRequirement.value;
      } else {
        return subRequirement.value;
      }
    },
  },
};
</script>

<style lang="scss">
.coi-review-dialog-content {
  width: 100%;
  background-color: $aon-gray-07 !important;
  overflow-y: auto;

  .button-container {
    width: 100%;
  }

  .pdf-content {
    overflow: auto;
    box-shadow: inset 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  .title-bar {
    height: 3rem;
    background: $white;
    box-shadow: 0px 10px 20px rgba(0, 17, 53, 0.04),
      0px 2px 6px rgba(0, 17, 53, 0.04), 0px 0px 1px rgba(0, 17, 53, 0.04);

    .magnify-icons {
      border-bottom: 2px solid $aon-peacock;
      cursor: pointer;
    }
  }
}
</style>
