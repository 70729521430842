<template>
  <div class="insurance">
    <h2 class="insurance-name">
      {{ $t('coiReview.validationPanel.workers.title') }}
    </h2>

    <LabeledInput
      :label="$t('coiReview.validationPanel.policyNumber')"
      :required="true"
    >
      <v-text-field
        v-model="$v.fields.workersCompensation.policyNumber.$model"
        :error-messages="
          insurance.errors($v.fields.workersCompensation.policyNumber)
        "
        validate-on-blur
        outlined
        dense
        type="text"
      />
    </LabeledInput>

    <AutocompleteCarriers
      :insurance="insurance"
      :insurancelob="$v.fields.workersCompensation"
    />

    <LabeledInput
      :label="$t('coiReview.validationPanel.effectiveDate')"
      :required="true"
    >
      <DatePicker
        :date.sync="effectiveDate"
        :error-messages="
          insurance.errors($v.fields.workersCompensation.effectiveDate)
        "
        label=""
        dense
      />
    </LabeledInput>

    <LabeledInput
      :label="$t('coiReview.validationPanel.expirationDate')"
      :required="true"
    >
      <DatePicker
        :date.sync="expirationDate"
        :error-messages="
          insurance.errors($v.fields.workersCompensation.expirationDate)
        "
        label=""
        dense
      />
    </LabeledInput>

    <div class="checkbox-container">
      <v-checkbox
        v-model="$v.fields.workersCompensation.perStatute.$model"
        :error-messages="
          insurance.errors($v.fields.workersCompensation.perStatute)
        "
        color="aon-peacock"
        :label="$t('coiReview.validationPanel.workers.perStatute')"
      />
    </div>

    <div v-if="this.$v.fields.isMigrated.$model">
      <Endorsement
        :options="clientList"
        :label="$t('endorsements.waiverOfSubrogation')"
        :display-options="
          this.$v.fields.workersCompensation.waiverOfSubrogation
        "
        @updateList="updateWaiverOfSubrogation"
      />
    </div>
    <div v-else>
      <div class="checkbox-container">
        <v-checkbox
          v-model="$v.fields.workersCompensation.waiverOfSubrogation.$model"
          :error-messages="
            insurance.errors($v.fields.workersCompensation.waiverOfSubrogation)
          "
          color="aon-peacock"
          :label="$t('coiReview.validationPanel.workers.waiverOfSubrogation')"
        />
      </div>
    </div>

    <div v-if="!isMigrated">
      <LabeledInput
        :label="$t('coiReview.validationPanel.workers.eachAccident')"
      >
        <v-text-field
          v-model.number="$v.fields.workersCompensation.elEachAccident.$model"
          :error-messages="
            insurance.errors($v.fields.workersCompensation.elEachAccident)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="$t('coiReview.validationPanel.workers.diseaseEachEmployee')"
      >
        <v-text-field
          v-model.number="
            $v.fields.workersCompensation.elDiseaseEaEmployee.$model
          "
          :error-messages="
            insurance.errors($v.fields.workersCompensation.elDiseaseEaEmployee)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>

      <LabeledInput
        :label="$t('coiReview.validationPanel.workers.diseasePolicy')"
      >
        <v-text-field
          v-model.number="
            $v.fields.workersCompensation.elDiseasePolicyLimit.$model
          "
          :error-messages="
            insurance.errors($v.fields.workersCompensation.elDiseasePolicyLimit)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>
    </div>
  </div>
</template>

<script>
import AutocompleteCarriers from '@/components/CoiReview/AutocompleteCarriers';
import Endorsement from '@/components/Endorsement';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';

export default {
  name: 'WorkersCompensation',
  components: {
    AutocompleteCarriers,
    Endorsement,
    DatePicker,
    LabeledInput,
  },
  props: {
    insurance: {
      type: Object,
      default: () => {},
    },
    clientList: {
      type: Array,
      default: () => [],
    },
    isMigrated: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    $v() {
      return this.insurance.$v;
    },
    effectiveDate: {
      get() {
        return this.$v.fields.workersCompensation.effectiveDate.$model
          ? this.$moment
              .utc(this.$v.fields.workersCompensation.effectiveDate.$model)
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        return (this.$v.fields.workersCompensation.effectiveDate.$model = value
          ? this.$moment.utc(value).format()
          : null);
      },
    },
    expirationDate: {
      get() {
        return this.$v.fields.workersCompensation.expirationDate.$model
          ? this.$moment
              .utc(this.$v.fields.workersCompensation.expirationDate.$model)
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$v.fields.workersCompensation.expirationDate.$model = value
          ? this.$moment.utc(value).format()
          : null;
      },
    },
  },
  methods: {
    updateWaiverOfSubrogation({ selectedValues }) {
      this.$v.fields.workersCompensation.endorsements.waiverOfSubrogation.$model = selectedValues;
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance {
  border-top: $border-gray-06;
  border-bottom: $border-gray-06;
  padding: 20px;

  &-name {
    color: #46535e;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 18px;
  }
}

.checkbox-container {
  display: flex;
  align-items: center;

  & .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
