import axios from 'axios';

const baseUrl = 'api/coi-data-extraction';

export function getExtractedDocuments(params) {
  return axios
    .get(`${baseUrl}/get-documents`, { params })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export function getNonRejectedDocuments(params) {
  return axios
    .get(`${baseUrl}/get-non-rejected-documents`, { params })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

export function getCoverages(extractionId) {
  return axios
    .get(`${baseUrl}/get-coverage?extractionId=${extractionId}`)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}
