<template>
  <div class="coi-review ma-0 pa-0">
    <v-navigation-drawer class="coi-review-sidebar" absolute permanent>
      <v-list>
        <v-list-item>
          <v-list-item-content class="py-2">
            <v-list-item-title>
              <h5 class="aon-peacock--text">
                {{ $t('coiReview.pageTitle') }}
              </h5>
            </v-list-item-title>
            <v-text-field
              v-model="searchText"
              class="py-2"
              :placeholder="$t('coiReview.search')"
              outlined
              dense
              hide-details
              @input="filterPolicies(null)"
            >
              <v-icon slot="append" small>fal fa-search</v-icon>
            </v-text-field>
            <TableFiltersSelect
              :filter-types="filterTypes"
              bottom-chips
              @change="filterPolicies"
            />
          </v-list-item-content>
        </v-list-item>
        <template v-for="(document, i) in policies">
          <v-list-item
            :key="i"
            two-line
            :class="
              document.fileId === selectedPolicy.fileId ? 'active-item' : ''
            "
            :disabled="coiLoading"
            @click="changeSelectedPolicy(i)"
          >
            <v-list-item-content class="pa-2 policy-list-item">
              <v-list-item-title class="d-flex justify-space-between mb-2">
                <div class="text--overflow">{{ document.fileName }}</div>
                <div class="caption ml-4">
                  {{ $moment.utc(document.processDate).format('MMM-DD-yyyy') }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex justify-space-between">
                <div class="text--overflow">{{ document.name }}</div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-if="!loading && !policies.length">
          <v-list-item-content class="pa-2 policy-list-item">
            <v-list-item-title class="d-flex justify-center my-2">
              {{ $t('coiReview.noResults') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <AppButton
        v-show="isNextPageToLoad"
        class="pa-2"
        full-width
        outlined
        :loading="loading"
        @click="loadPage()"
      >
        {{ $t('coiReview.loadMore') }}
      </AppButton>
    </v-navigation-drawer>
    <div class="coi-review-container py-0">
      <v-layout fill-height>
        <CoiReviewDialog
          v-if="loading || policies.length"
          :policy-list-loading="loading"
          :policy="extractedPolicyData"
          :document="selectedPolicy"
          :extracted-coverages="extractedCoverages"
          :saving="policySaving"
          @approved="updateCoi"
          @loading="isLoading => (coiLoading = isLoading)"
        />
        <div v-else class="absolute-center text-center">
          <v-avatar size="56" color="aon-teal-lightest">
            <i class="fad fa-file-check fa-2x teal-dark-text"></i>
          </v-avatar>
          <div class="title">{{ $t('coiReview.emptyTitle') }}</div>
          <div class="subtitle-1">{{ $t('coiReview.emptySubtitle') }}</div>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/insurancePolicies';
import * as policyApi from '@/api/policies';
import CoiReviewDialog from '@/components/CoiReview/CoiReviewDialog';
import TableFiltersSelect from '@aon/cfs-components/src/components/DataTables/TableFiltersSelect';
import { debounce } from 'lodash-es';
import { validate as uuidValidate } from 'uuid';
import { getNonRejectedDocuments, getCoverages } from '@/api/coiDataExtraction';

export default {
  name: 'CoiReview',
  components: {
    CoiReviewDialog,
    TableFiltersSelect,
  },
  data() {
    return {
      coiLoading: false,
      policies: [],
      selectedPolicy: {},
      selectedPolicyIndex: 0,
      extractedPolicyData: {},
      extractedCoverages: [],
      loading: true,
      index: 0,
      page: 1,
      pageSize: 25,
      policySaving: false,
      pageCount: 999,
      searchText: '',
      filters: {},
      filterTypes: [
        {
          typeText: this.$t('coiReview.filters.insuranceType'),
          typeValue: 'insuranceType',
          values: [
            {
              text: this.$t('mappings.insurance.IP'),
              value: 'IP',
            },
            {
              text: this.$t('mappings.insurance.GL'),
              value: 'GL',
            },
            {
              text: this.$t('mappings.insurance.ERRS_OMISNS'),
              value: 'ERRS_OMISNS',
            },
            {
              text: this.$t('mappings.insurance.CYBER'),
              value: 'CYBER',
            },
            {
              text: this.$t('mappings.insurance.WRK_COMP'),
              value: 'WRK_COMP',
            },
            {
              text: this.$t('mappings.insurance.COM_AUTO'),
              value: 'COM_AUTO',
            },
            {
              text: this.$t('mappings.insurance.PROD_LIAB'),
              value: 'PROD_LIAB',
            },
            {
              text: this.$t('mappings.insurance.PROPERTY'),
              value: 'PROPERTY',
            },
            {
              text: this.$t('mappings.insurance.BUSINESS'),
              value: 'BUSINESS',
            },
            {
              text: this.$t('mappings.insurance.UMB_EXCESS'),
              value: 'UMB_EXCESS',
            },
            {
              text: this.$t('mappings.insurance.INLAND_MARINE'),
              value: 'INLAND_MARINE',
            },
            {
              text: this.$t('mappings.insurance.MISC_EQUIP'),
              value: 'MISC_EQUIP',
            },
            {
              text: this.$t('mappings.insurance.AUTO_LIAB'),
              value: 'AUTO_LIAB',
            },
            {
              text: this.$t('mappings.insurance.EO_PROF_LIAB'),
              value: 'EO_PROF_LIAB',
            },
            {
              text: this.$t('mappings.insurance.TECH_EO'),
              value: 'TECH_EO',
            },
            {
              text: this.$t('mappings.insurance.EMPL_PRACT_LIAB'),
              value: 'EMPL_PRACT_LIAB',
            },
            {
              text: this.$t('mappings.insurance.DIRECT_OFFICE'),
              value: 'DIRECT_OFFICE',
            },
            {
              text: this.$t('mappings.insurance.FID_LIAB'),
              value: 'FID_LIAB',
            },
            {
              text: this.$t('mappings.insurance.MISC_RENT_EQUIP'),
              value: 'MISC_RENT_EQUIP',
            },
          ],
        },
        {
          typeText: this.$t('coiReview.filters.submittalDate.title'),
          typeValue: 'updatedAfter',
          values: [
            {
              text: this.$t('coiReview.filters.submittalDate.lastDay'),
              value: this.$moment
                .utc()
                .subtract(1, 'day')
                .format('YYYY-MM-DD'),
            },
            {
              text: this.$t('coiReview.filters.submittalDate.lastWeek'),
              value: this.$moment
                .utc()
                .subtract(1, 'week')
                .format('YYYY-MM-DD'),
            },
            {
              text: this.$t('coiReview.filters.submittalDate.lastMonth'),
              value: this.$moment
                .utc()
                .subtract(1, 'month')
                .format('YYYY-MM-DD'),
            },
          ],
        },
      ],
    };
  },
  computed: {
    isNextPageToLoad() {
      return this.page <= this.pageCount;
    },
  },
  async created() {
    this.loading = true;
    try {
      await this.loadPage();

      if (this.policies.length) {
        this.changeSelectedPolicy(this.selectedPolicyIndex);
      }
    } catch (err) {
      this.$AppEventBus.$emit('snacktime', {
        type: 'error',
        message: 'Failed to retrieve the policies',
      });
    }
    this.loading = false;
  },
  methods: {
    filterPolicies: debounce(async function(filters = null) {
      if (filters !== null) {
        this.filters = filters;
      }

      // SearchText doesn't work with extraction data endpoint
      if (uuidValidate(this.searchText)) {
        await this.loadPolicy();
      } else {
        this.policies = [];
        this.page = 1;
        this.pageCount = 0;

        await this.loadPage();
      }

      if (this.policies.length) {
        this.selectedPolicy = this.policies[this.selectedPolicyIndex];
      }
    }, 500),
    async loadPolicy() {
      try {
        const response = await api.getPolicy(this.searchText);
        response.data.companyName = '';
        this.policies = response.data.coiStatus == null ? [response.data] : [];
      } catch (e) {
        this.policies = [];
      }

      this.page = 1;
      this.pageCount = 0;
    },
    async loadPage() {
      this.loading = true;

      let params = {
        page: this.page,
        pageSize: this.pageSize,
      };

      if (this.searchText !== '') {
        params['searchText'] = this.searchText;
      }

      try {
        const response = await getNonRejectedDocuments(params);
        const policyDocs = response.results.map(doc => {
          const policy = {
            ...doc,
            processDate: doc.createdAt,
          };
          return policy;
        });
        this.page = response.page + 1;
        this.pageCount = response.pageCount;

        this.policies = this.policies.concat(policyDocs);
      } catch (error) {
        console.error('Error retrieving documents: ', error);
      }

      this.loading = false;
    },
    updatePolicyList(fileId) {
      this.policies = this.policies.filter(p => p.fileId !== fileId);
      if (this.policies.length) {
        const index =
          this.selectedPolicyIndex > this.policies.length - 1
            ? this.selectedPolicyIndex - 1
            : this.selectedPolicyIndex;
        this.changeSelectedPolicy(index);
      } else {
        this.changeSelectedPolicy(0);
      }
    },
    async updateCoi({ status, rejectionReason, updatedPolicy }) {
      try {
        this.policySaving = true;
        const selectedPolicyFileId = updatedPolicy.fileId;

        if (rejectionReason) {
          const { fileId, extractionResultId } = this.selectedPolicy;
          const result = await policyApi.rejectPolicy(
            extractionResultId,
            fileId,
            rejectionReason
          );

          if (result.status === 204 || result.status === 200) {
            this.$AppEventBus.$emit('snacktime', {
              type: 'success',
              message: 'The issue was successfully reported',
            });
            this.updatePolicyList(selectedPolicyFileId);
          } else {
            this.$AppEventBus.$emit('snacktime', {
              type: 'error',
              message: 'Error: issue reporting unsuccessful',
            });
          }
        } else {
          const brokerName =
            updatedPolicy.brokeredBy === 'other'
              ? updatedPolicy.brokerName
              : updatedPolicy.brokeredBy;

          const generalInfo = {
            fileId: updatedPolicy.fileId,
            policyType: updatedPolicy.policyType,
            territory: updatedPolicy.territory,
            brokeredBy: brokerName,
            vendorId: updatedPolicy.vendor.vendorId,
            clientName: updatedPolicy.clientName,
            selectedCertificateHolder: updatedPolicy.selectedCertificateHolder,
          };

          const workersCompPolicy = this.buildWorkersCompPolicy(
            updatedPolicy,
            generalInfo
          );

          const umbrellaPolicy = this.buildUmbrellaPolicy(
            updatedPolicy,
            generalInfo
          );

          const glPolicy = this.buildGeneralLiabilityPolicy(
            updatedPolicy,
            generalInfo
          );

          const liquorPolicy = this.buildLiquorLiabilityPolicy(
            updatedPolicy,
            generalInfo
          );

          const commercialAutoPolicy = this.buildCommercialAutoPolicy(
            updatedPolicy,
            generalInfo
          );

          const empPracticesPolicy = this.buildEmpPracticesLiabilityPolicy(
            updatedPolicy,
            generalInfo
          );

          const errorProfessionalPolicy = this.buildErrorProfessionalLiabilityPolicy(
            updatedPolicy,
            generalInfo
          );

          const listOfPolicies = [];

          if (workersCompPolicy) {
            listOfPolicies.push(workersCompPolicy);
          }

          if (umbrellaPolicy) {
            listOfPolicies.push(umbrellaPolicy);
          }

          if (glPolicy) {
            listOfPolicies.push(glPolicy);
          }

          if (liquorPolicy) {
            listOfPolicies.push(liquorPolicy);
          }

          if (commercialAutoPolicy) {
            listOfPolicies.push(commercialAutoPolicy);
          }

          if (empPracticesPolicy) {
            listOfPolicies.push(empPracticesPolicy);
          }

          if (errorProfessionalPolicy) {
            listOfPolicies.push(errorProfessionalPolicy);
          }

          const tenantId = updatedPolicy.vendor.tenantId;
          const result = await policyApi.createApprovedMultiplePolicies(
            listOfPolicies,
            tenantId
          );

          if (result.status === 204 || result.status === 200) {
            this.updatePolicyList(selectedPolicyFileId);
            this.$AppEventBus.$emit('snacktime', {
              type: 'success',
              message: `The COI was ${status}`,
            });
          } else {
            this.$AppEventBus.$emit('snacktime', {
              type: 'error',
              message: `Failed to update the COI status`,
            });
          }
        }
      } catch (err) {
        console.error(err);

        const { status, responseText } = err.request;

        if (status === 400) {
          this.$AppEventBus.$emit('snacktime', {
            type: 'error',
            message: responseText,
          });
        } else {
          this.$AppEventBus.$emit('snacktime', {
            type: 'error',
            message: rejectionReason
              ? 'Error: issue reporting unsuccessful'
              : 'Failed to update the COI status',
          });
        }
      }
      this.policySaving = false;
    },
    hasBlanketOption(endorsementList) {
      return endorsementList.some(
        endorsement => endorsement.companyName === 'Blanket'
      );
    },
    getEndorsements(
      endorsementName = '',
      isChecked = false,
      endorsementList = []
    ) {
      if (!isChecked) return [];

      let selectedValues = [];
      if (endorsementList?.length) {
        if (this.hasBlanketOption(endorsementList)) {
          selectedValues = [
            {
              type: 'ENDORSEMENT',
              name: endorsementName,
              value: 'BLANKET',
            },
          ];
        } else {
          selectedValues = endorsementList
            .filter(endorsement => endorsement.companyName)
            .map(endorsement => {
              return {
                type: 'ENDORSEMENT',
                name: endorsementName,
                value: endorsement.companyName,
              };
            });
        }
      }
      return selectedValues;
    },
    buildWorkersCompPolicy(policyList, generalInfo) {
      const currentPolicy = policyList.workersCompensation || null;
      const isMigrated = policyList.isMigrated;

      let finalPolicy = null;
      let subrequirements = [];

      if (currentPolicy) {
        if (currentPolicy.elEachAccident) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'EL_EACH_ACC',
            value: `${currentPolicy.elEachAccident}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (currentPolicy.elDiseaseEaEmployee) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'EL_EACH_DIS_EA_EMP',
            value: `${currentPolicy.elDiseaseEaEmployee}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (currentPolicy.elDiseasePolicyLimit) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'EL_DIS_POL_LIM',
            value: `${currentPolicy.elDiseasePolicyLimit}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (currentPolicy.perStatute) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'PER_STATUTE',
            value: generalInfo.clientName,
            meta: { TypeValue: 'BOOL' },
          });
        }

        if (isMigrated) {
          const {
            waiverOfSubrogation: isCheckedWaiverOfSubrogation,
            endorsements,
          } = currentPolicy;

          const waiverOfSubrogationReq = this.getEndorsements(
            'WAIVER_OF_SUBROGATION',
            isCheckedWaiverOfSubrogation,
            endorsements.waiverOfSubrogation
          );

          subrequirements = [
            {
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            },
            {
              type: 'COI_CLIENT',
              name: 'CLIENT_COMPANY_NAME',
              value: generalInfo.clientName,
            },
            ...waiverOfSubrogationReq,
          ];
        } else {
          if (currentPolicy.waiverOfSubrogation) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'WAIVER_OF_SUBROGATION',
              value: generalInfo.clientName,
            });
          }

          if (generalInfo.selectedCertificateHolder) {
            subrequirements.push({
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            });
          }

          subrequirements.push({
            type: 'COI_CLIENT',
            name: 'CLIENT_COMPANY_NAME',
            value: generalInfo.clientName,
          });
        }

        finalPolicy = {
          ...generalInfo,
          insuranceType: 'WRK_COMP',
          id: currentPolicy.policyId,
          policyNumber: currentPolicy.policyNumber,
          carrier: currentPolicy.carrier,
          carrierAonId: currentPolicy.carrierAonId,
          carrierNaic: currentPolicy.carrierNaic,
          effectiveDate: currentPolicy.effectiveDate,
          expirationDate: currentPolicy.expirationDate,
          subrequirements: [...subrequirements],
        };
      }

      return finalPolicy;
    },
    buildUmbrellaPolicy(policyList, generalInfo) {
      const currentPolicy = policyList.umbrella || null;
      const isMigrated = policyList.isMigrated;

      let finalPolicy = null;
      let subrequirements = [];

      if (currentPolicy) {
        if (currentPolicy.umExcessPresent) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'EXCESS_PRESENT',
            value: generalInfo.clientName,
            meta: { TypeValue: 'BOOL' },
          });
        }

        if (currentPolicy.umUmbrellaPresent) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'UMBRELLA_PRESENT',
            value: generalInfo.clientName,
            meta: { TypeValue: 'BOOL' },
          });
        }

        if (isMigrated) {
          const {
            additionalInsured: isCheckedAdditionalInsured,
            waiverOfSubrogation: isCheckedWaiverOfSubrogation,
            primaryNonContributory: isCheckedPrimaryNonContributory,
            endorsements,
          } = currentPolicy;

          const additionalEnsuredReq = this.getEndorsements(
            'ADDITIONAL_INSURED',
            isCheckedAdditionalInsured,
            endorsements.additionalInsured
          );
          const waiverOfSubrogationReq = this.getEndorsements(
            'WAIVER_OF_SUBROGATION',
            isCheckedWaiverOfSubrogation,
            endorsements.waiverOfSubrogation
          );
          const primaryNonContributoryReq = this.getEndorsements(
            'PRIMARY_NON_CONTRIBUTORY',
            isCheckedPrimaryNonContributory,
            endorsements.primaryNonContributory
          );

          subrequirements = [
            {
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            },
            {
              type: 'COI_CLIENT',
              name: 'CLIENT_COMPANY_NAME',
              value: generalInfo.clientName,
            },
            ...additionalEnsuredReq,
            ...waiverOfSubrogationReq,
            ...primaryNonContributoryReq,
          ];
        } else {
          if (currentPolicy.additionalInsured) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'ADDITIONAL_INSURED',
              value: generalInfo.clientName,
            });
          }

          if (currentPolicy.waiverOfSubrogation) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'WAIVER_OF_SUBROGATION',
              value: generalInfo.clientName,
            });
          }

          if (currentPolicy.primaryNonContributory) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'PRIMARY_NON_CONTRIBUTORY',
              value: generalInfo.clientName,
            });
          }

          if (generalInfo.selectedCertificateHolder) {
            subrequirements.push({
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            });
          }

          subrequirements.push({
            type: 'COI_CLIENT',
            name: 'CLIENT_COMPANY_NAME',
            value: generalInfo.clientName,
          });
        }

        finalPolicy = {
          ...generalInfo,
          insuranceType: 'UMB_EXCESS',
          id: currentPolicy.policyId,
          policyNumber: currentPolicy.policyNumber,
          carrier: currentPolicy.carrier,
          carrierAonId: currentPolicy.carrierAonId,
          carrierNaic: currentPolicy.carrierNaic,
          effectiveDate: currentPolicy.effectiveDate,
          expirationDate: currentPolicy.expirationDate,
          perOccurrenceCoverage: currentPolicy.eachOccurrence,
          coverage: currentPolicy.umAggregate,
          subrequirements: [...subrequirements],
        };
      }

      return finalPolicy;
    },
    buildGeneralLiabilityPolicy(policyList, generalInfo) {
      const currentPolicy = policyList.generalLiability || null;
      const isMigrated = policyList.isMigrated;

      let finalPolicy = null;
      let subrequirements = [];

      if (currentPolicy) {
        if (currentPolicy.damageToRentedPremises) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'DMG_TO_RENTED_PREM',
            value: `${currentPolicy.damageToRentedPremises}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (currentPolicy.productsComp) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'PROD_COMP_OG_AGG',
            value: `${currentPolicy.productsComp}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (currentPolicy.personalAdvInjury) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'PERS_ADV_INJ',
            value: `${currentPolicy.personalAdvInjury}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (currentPolicy.perPolicy) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'APPLIES_PER_POLICY',
            value: generalInfo.clientName,
          });
        }

        if (currentPolicy.perProject) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'APPLIES_PER_PROJECT',
            value: generalInfo.clientName,
          });
        }

        if (currentPolicy.perLocation) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'APPLIES_PER_LOCATION',
            value: generalInfo.clientName,
          });
        }

        if (currentPolicy.coverOccurrence) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'POLICY_COVER_OCCURRENCE',
            value: generalInfo.clientName,
          });
        }

        if (currentPolicy.coverClaimsMade) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'POLICY_COVER_CLAIMS_MADE',
            value: generalInfo.clientName,
          });
        }

        if (isMigrated) {
          const {
            additionalInsured: isCheckedAdditionalInsured,
            waiverOfSubrogation: isCheckedWaiverOfSubrogation,
            primaryNonContributory: isCheckedPrimaryNonContributory,
            endorsements,
          } = currentPolicy;

          const additionalEnsuredReq = this.getEndorsements(
            'ADDITIONAL_INSURED',
            isCheckedAdditionalInsured,
            endorsements.additionalInsured
          );
          const waiverOfSubrogationReq = this.getEndorsements(
            'WAIVER_OF_SUBROGATION',
            isCheckedWaiverOfSubrogation,
            endorsements.waiverOfSubrogation
          );
          const primaryNonContributoryReq = this.getEndorsements(
            'PRIMARY_NON_CONTRIBUTORY',
            isCheckedPrimaryNonContributory,
            endorsements.primaryNonContributory
          );

          subrequirements = [
            {
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            },
            {
              type: 'COI_CLIENT',
              name: 'CLIENT_COMPANY_NAME',
              value: generalInfo.clientName,
            },
            ...additionalEnsuredReq,
            ...waiverOfSubrogationReq,
            ...primaryNonContributoryReq,
          ];
        } else {
          if (currentPolicy.additionalInsured) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'ADDITIONAL_INSURED',
              value: generalInfo.clientName,
            });
          }

          if (currentPolicy.waiverOfSubrogation) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'WAIVER_OF_SUBROGATION',
              value: generalInfo.clientName,
            });
          }

          if (currentPolicy.primaryNonContributory) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'PRIMARY_NON_CONTRIBUTORY',
              value: generalInfo.clientName,
            });
          }

          if (generalInfo.selectedCertificateHolder) {
            subrequirements.push({
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            });
          }

          subrequirements.push({
            type: 'COI_CLIENT',
            name: 'CLIENT_COMPANY_NAME',
            value: generalInfo.clientName,
          });
        }

        finalPolicy = {
          ...generalInfo,
          insuranceType: 'GL',
          id: currentPolicy.policyId,
          policyNumber: currentPolicy.policyNumber,
          carrier: currentPolicy.carrier,
          carrierAonId: currentPolicy.carrierAonId,
          carrierNaic: currentPolicy.carrierNaic,
          effectiveDate: currentPolicy.effectiveDate,
          expirationDate: currentPolicy.expirationDate,
          perOccurrenceCoverage: currentPolicy.eachOccurrence,
          coverage: currentPolicy.aggregate,
          deductible: currentPolicy.deductible,
          subrequirements: [...subrequirements],
        };
      }

      return finalPolicy;
    },
    buildLiquorLiabilityPolicy(policyList, generalInfo) {
      const currentPolicy = policyList.liquorLiability || null;
      const isMigrated = policyList.isMigrated;

      let finalPolicy = null;
      let subrequirements = [];

      if (currentPolicy) {
        if (isMigrated) {
          const {
            additionalInsured: isCheckedAdditionalInsured,
            endorsements,
          } = currentPolicy;

          const additionalEnsuredReq = this.getEndorsements(
            'ADDITIONAL_INSURED',
            isCheckedAdditionalInsured,
            endorsements.additionalInsured
          );

          subrequirements = [
            {
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            },
            {
              type: 'COI_CLIENT',
              name: 'CLIENT_COMPANY_NAME',
              value: generalInfo.clientName,
            },
            ...additionalEnsuredReq,
          ];
        } else {
          if (currentPolicy.additionalInsured) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'ADDITIONAL_INSURED',
              value: generalInfo.clientName,
            });
          }

          if (generalInfo.selectedCertificateHolder) {
            subrequirements.push({
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            });
          }

          subrequirements.push({
            type: 'COI_CLIENT',
            name: 'CLIENT_COMPANY_NAME',
            value: generalInfo.clientName,
          });
        }

        finalPolicy = {
          ...generalInfo,
          insuranceType: 'LIQUOR_LIAB',
          id: currentPolicy.policyId,
          policyNumber: currentPolicy.policyNumber,
          carrier: currentPolicy.carrier,
          carrierAonId: currentPolicy.carrierAonId,
          carrierNaic: currentPolicy.carrierNaic,
          effectiveDate: currentPolicy.effectiveDate,
          expirationDate: currentPolicy.expirationDate,
          perOccurrenceCoverage: currentPolicy.llEachCommonCause,
          coverage: currentPolicy.aggregate,
          deductible: 0,
          subrequirements: [...subrequirements],
        };
      }

      return finalPolicy;
    },
    buildCommercialAutoPolicy(policyList, generalInfo) {
      const currentPolicy = policyList.commercialAuto || null;
      const isMigrated = policyList.isMigrated;

      let finalPolicy = null;
      let subrequirements = [];

      if (currentPolicy) {
        if (currentPolicy.caAnyAuto) {
          subrequirements.push({
            type: 'ADDITIONAL_COVERAGE',
            name: 'AUTO_LIAB',
            value: 'ANY',
          });
        }

        if (currentPolicy.caOwnedAutosOnly) {
          subrequirements.push({
            type: 'ADDITIONAL_COVERAGE',
            name: 'AUTO_LIAB',
            value: 'OWNED',
          });
        }

        if (currentPolicy.caNonOwnedAutosOnly) {
          subrequirements.push({
            type: 'ADDITIONAL_COVERAGE',
            name: 'AUTO_LIAB',
            value: 'NON_OWNED',
          });
        }

        if (currentPolicy.caHiredAutosOnly) {
          subrequirements.push({
            type: 'ADDITIONAL_COVERAGE',
            name: 'AUTO_LIAB',
            value: 'HIRED',
          });
        }

        if (currentPolicy.caScheduledAutos) {
          subrequirements.push({
            type: 'ADDITIONAL_COVERAGE',
            name: 'AUTO_LIAB',
            value: 'SCHEDULED',
          });
        }

        if (currentPolicy.caBodilyInjuryPerPerson) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'BODY_INJ_PER_PER',
            value: `${currentPolicy.caBodilyInjuryPerPerson}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (currentPolicy.caBodilyInjuryPerAccident) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'BODY_INJ_PER_ACC',
            value: `${currentPolicy.caBodilyInjuryPerAccident}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (currentPolicy.caPropertyDamagePerAccident) {
          subrequirements.push({
            type: 'SUB_LIMIT',
            name: 'PROP_DAM_PER_ACC',
            value: `${currentPolicy.caPropertyDamagePerAccident}`,
            meta: { TypeValue: 'AMOUNT' },
          });
        }

        if (isMigrated) {
          const {
            caAdditionalInsured: isCheckedAdditionalInsured,
            waiverOfSubrogation: isCheckedWaiverOfSubrogation,
            primaryNonContributory: isCheckedPrimaryNonContributory,
            endorsements,
          } = currentPolicy;

          const additionalEnsuredReq = this.getEndorsements(
            'ADDITIONAL_INSURED',
            isCheckedAdditionalInsured,
            endorsements.additionalInsured
          );
          const waiverOfSubrogationReq = this.getEndorsements(
            'WAIVER_OF_SUBROGATION',
            isCheckedWaiverOfSubrogation,
            endorsements.waiverOfSubrogation
          );
          const primaryNonContributoryReq = this.getEndorsements(
            'PRIMARY_NON_CONTRIBUTORY',
            isCheckedPrimaryNonContributory,
            endorsements.primaryNonContributory
          );

          subrequirements = [
            {
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            },
            {
              type: 'COI_CLIENT',
              name: 'CLIENT_COMPANY_NAME',
              value: generalInfo.clientName,
            },
            ...additionalEnsuredReq,
            ...waiverOfSubrogationReq,
            ...primaryNonContributoryReq,
          ];
        } else {
          if (currentPolicy.caAdditionalInsured) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'ADDITIONAL_INSURED',
              value: generalInfo.clientName,
            });
          }

          if (currentPolicy.waiverOfSubrogation) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'WAIVER_OF_SUBROGATION',
              value: generalInfo.clientName,
            });
          }

          if (currentPolicy.primaryNonContributory) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'PRIMARY_NON_CONTRIBUTORY',
              value: generalInfo.clientName,
            });
          }

          if (generalInfo.selectedCertificateHolder) {
            subrequirements.push({
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            });
          }

          subrequirements.push({
            type: 'COI_CLIENT',
            name: 'CLIENT_COMPANY_NAME',
            value: generalInfo.clientName,
          });
        }

        finalPolicy = {
          ...generalInfo,
          insuranceType: 'COM_AUTO',
          id: currentPolicy.policyId,
          policyNumber: currentPolicy.policyNumber,
          carrier: currentPolicy.carrier,
          carrierAonId: currentPolicy.carrierAonId,
          carrierNaic: currentPolicy.carrierNaic,
          effectiveDate: currentPolicy.effectiveDate,
          expirationDate: currentPolicy.expirationDate,
          perOccurrenceCoverage: currentPolicy.caCombinedSingleLimit,
          coverage: 0,
          deductible: 0,
          subrequirements: [...subrequirements],
        };
      }

      return finalPolicy;
    },
    buildEmpPracticesLiabilityPolicy(policyList, generalInfo) {
      const currentPolicy = policyList.employmentPracticesLiability || null;

      let finalPolicy = null;
      const subrequirements = [];

      if (currentPolicy) {
        if (generalInfo.selectedCertificateHolder) {
          subrequirements.push({
            type: 'COI_CLIENT',
            name: 'CERTIFICATE_HOLDER',
            value: generalInfo.clientName,
          });
        }

        subrequirements.push({
          type: 'COI_CLIENT',
          name: 'CLIENT_COMPANY_NAME',
          value: generalInfo.clientName,
        });

        finalPolicy = {
          ...generalInfo,
          insuranceType: 'EMPL_PRACT_LIAB',
          id: currentPolicy.policyId,
          policyNumber: currentPolicy.policyNumber,
          carrier: currentPolicy.carrier,
          carrierAonId: currentPolicy.carrierAonId,
          carrierNaic: currentPolicy.carrierNaic,
          effectiveDate: currentPolicy.effectiveDate,
          expirationDate: currentPolicy.expirationDate,
          subrequirements: [...subrequirements],
        };
      }

      return finalPolicy;
    },
    buildErrorProfessionalLiabilityPolicy(policyList, generalInfo) {
      const currentPolicy = policyList.professionalLiability || null;
      const isMigrated = policyList.isMigrated;

      let finalPolicy = null;
      let subrequirements = [];

      if (currentPolicy) {
        if (isMigrated) {
          const {
            additionalInsured: isCheckedAdditionalInsured,
            endorsements,
          } = currentPolicy;

          const additionalEnsuredReq = this.getEndorsements(
            'ADDITIONAL_INSURED',
            isCheckedAdditionalInsured,
            endorsements.additionalInsured
          );

          subrequirements = [
            {
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            },
            {
              type: 'COI_CLIENT',
              name: 'CLIENT_COMPANY_NAME',
              value: generalInfo.clientName,
            },
            ...additionalEnsuredReq,
          ];
        } else {
          if (currentPolicy.additionalInsured) {
            subrequirements.push({
              type: 'ENDORSEMENT',
              name: 'ADDITIONAL_INSURED',
              value: generalInfo.clientName,
            });
          }

          if (generalInfo.selectedCertificateHolder) {
            subrequirements.push({
              type: 'COI_CLIENT',
              name: 'CERTIFICATE_HOLDER',
              value: generalInfo.clientName,
            });
          }

          subrequirements.push({
            type: 'COI_CLIENT',
            name: 'CLIENT_COMPANY_NAME',
            value: generalInfo.clientName,
          });
        }

        finalPolicy = {
          ...generalInfo,
          insuranceType: 'EO_PROF_LIAB',
          id: currentPolicy.policyId,
          policyNumber: currentPolicy.policyNumber,
          carrier: currentPolicy.carrier,
          carrierAonId: currentPolicy.carrierAonId,
          carrierNaic: currentPolicy.carrierNaic,
          effectiveDate: currentPolicy.effectiveDate,
          expirationDate: currentPolicy.expirationDate,
          perOccurrenceCoverage: currentPolicy.eachOccurrence,
          coverage: currentPolicy.aggregate,
          subrequirements: [...subrequirements],
        };
      }

      return finalPolicy;
    },
    async changeSelectedPolicy(index) {
      this.selectedPolicyIndex = index;
      this.selectedPolicy = this.policies[this.selectedPolicyIndex];
      const extractionId = this.selectedPolicy.extractionId;

      const coverageResponse = await getCoverages(extractionId);
      this.extractedCoverages =
        coverageResponse.extractedCoverages[0].extractedPolicies;

      const generalLiability = this.extractedCoverages.find(
        policy => policy.policyType === 'GeneralLiability'
      );
      const umbrella = this.extractedCoverages.find(
        policy => policy.policyType === 'Umbrella'
      );
      const liquorLiability = this.extractedCoverages.find(
        policy => policy.policyType === 'LiquorLiability'
      );
      const workersComp = this.extractedCoverages.find(
        policy => policy.policyType === 'WorkersCompensation'
      );
      const commercialAuto = this.extractedCoverages.find(
        policy => policy.policyType === 'CommercialAuto'
      );
      const empPracticesLiability = this.extractedCoverages.find(
        policy => policy.policyType === 'EmploymentPracticesLiability'
      );
      const professionalLiability = this.extractedCoverages.find(
        policy => policy.policyType === 'ProfessionalLiability'
      );

      this.extractedPolicyData = {
        ...this.selectedPolicy,
        vendor: '',
        brokeredBy: '',
        clientName: '',
        selectedCertificateHolder: '',
        generalLiability: generalLiability || null,
        umbrella: umbrella || null,
        liquorLiability: liquorLiability || null,
        workersCompensation: workersComp || null,
        commercialAuto: commercialAuto || null,
        employmentPracticesLiability: empPracticesLiability || null,
        professionalLiability: professionalLiability || null,
      };
    },
    removeSelectedFilter(filter) {
      this.activeFilters = [
        ...this.activeFilters.filter(
          f => !(f.type === filter.type && f.value === filter.value)
        ),
      ];
    },
  },
};
</script>

<style lang="scss">
$sidebar-width: 300px;

.coi-review {
  height: 100vh;
  max-width: 100% !important;

  .coi-review-sidebar {
    width: $sidebar-width !important;
  }

  .coi-review-container {
    height: 100%;
    padding: 0 $sidebar-width;
  }

  .text--overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-list-item {
    border-bottom: $border-gray-06;
  }

  .active-item {
    background: $aon-teal-lightest;
    border: 1px solid $aon-teal;
  }

  [class$='-chip'] {
    .v-chip__close {
      color: $white;
      font-size: 14px !important;
      margin: 0 0 0 5px;
    }

    color: $white;
    background: $aon-teal !important;
  }
}
</style>
