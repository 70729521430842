<template>
  <div class="insurance">
    <h2 class="insurance-name">
      {{ $t('coiReview.validationPanel.employmentPracticesLiability.title') }}
    </h2>

    <LabeledInput
      :label="$t('coiReview.validationPanel.policyNumber')"
      :required="true"
    >
      <v-text-field
        v-model="$v.fields.employmentPracticesLiability.policyNumber.$model"
        :error-messages="
          insurance.errors($v.fields.employmentPracticesLiability.policyNumber)
        "
        validate-on-blur
        outlined
        dense
        type="text"
      />
    </LabeledInput>

    <AutocompleteCarriers
      :insurance="insurance"
      :insurancelob="$v.fields.employmentPracticesLiability"
    />

    <LabeledInput
      :label="$t('coiReview.validationPanel.effectiveDate')"
      :required="true"
    >
      <DatePicker
        :date.sync="effectiveDate"
        :error-messages="
          insurance.errors($v.fields.employmentPracticesLiability.effectiveDate)
        "
        label=""
        dense
      />
    </LabeledInput>

    <LabeledInput
      :label="$t('coiReview.validationPanel.expirationDate')"
      :required="true"
    >
      <DatePicker
        :date.sync="expirationDate"
        :error-messages="
          insurance.errors(
            $v.fields.employmentPracticesLiability.expirationDate
          )
        "
        label=""
        dense
      />
    </LabeledInput>

    <div v-if="!isMigrated">
      <LabeledInput
        :label="
          $t('coiReview.validationPanel.employmentPracticesLiability.aggregate')
        "
        :required="true"
      >
        <v-text-field
          v-model.number="
            $v.fields.employmentPracticesLiability.aggregate.$model
          "
          :error-messages="
            insurance.errors($v.fields.employmentPracticesLiability.aggregate)
          "
          type="number"
          class="hide-number-spinner"
          dense
          outlined
        />
      </LabeledInput>
    </div>
  </div>
</template>

<script>
import AutocompleteCarriers from '@/components/CoiReview/AutocompleteCarriers';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';

export default {
  name: 'EmploymentPracticesLiability',
  components: {
    AutocompleteCarriers,
    LabeledInput,
    DatePicker,
  },
  props: {
    insurance: {
      type: Object,
      default: () => {},
    },
    isMigrated: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    $v() {
      return this.insurance.$v;
    },
    effectiveDate: {
      get() {
        return this.$v.fields.employmentPracticesLiability.effectiveDate.$model
          ? this.$moment
              .utc(
                this.$v.fields.employmentPracticesLiability.effectiveDate.$model
              )
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$v.fields.employmentPracticesLiability.effectiveDate.$model = value
          ? this.$moment.utc(value).format()
          : null;
      },
    },
    expirationDate: {
      get() {
        return this.$v.fields.employmentPracticesLiability.expirationDate.$model
          ? this.$moment
              .utc(
                this.$v.fields.employmentPracticesLiability.expirationDate
                  .$model
              )
              .format('YYYY-MM-DD')
          : null;
      },
      set(value) {
        this.$v.fields.employmentPracticesLiability.expirationDate.$model = value
          ? this.$moment.utc(value).format()
          : null;
      },
    },
  },
  watch: {
    isMigrated(newValue) {
      if (newValue) {
        this.$v.fields.employmentPracticesLiability.aggregate.$model = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance {
  border-top: $border-gray-06;
  border-bottom: $border-gray-06;
  padding: 20px;

  &-name {
    color: #46535e;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 18px;
  }
}
</style>
